// 计算时间范围
export function fnComputeTime(TIME) {
  let date = new Date();
  let Y = date.getFullYear() + "-";
  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate() + " ";

  if (TIME == "3M") {
    if (date.getMonth() + 1 - 3 >= 1) {
      M =
        (date.getMonth() + 1 - 3 < 10
          ? "0" + (date.getMonth() + 1 - 3)
          : date.getMonth() + 1 - 3) + "-";
    } else {
      Y = date.getFullYear() - 1 + "-";
      M =
        (12 - 3 + date.getMonth() + 1 < 10
          ? "0" + (12 - 3 + date.getMonth() + 1)
          : 12 - 3 + date.getMonth() + 1) + "-";
    }
  }

  if (TIME == "6M") {
    if (date.getMonth() + 1 - 6 >= 1) {
      M =
        (date.getMonth() + 1 - 6 < 10
          ? "0" + (date.getMonth() + 1 - 6)
          : date.getMonth() + 1 - 6) + "-";
    } else {
      Y = date.getFullYear() - 1 + "-";
      M =
        (12 - 6 + date.getMonth() + 1 < 10
          ? "0" + (12 - 6 + date.getMonth() + 1)
          : 12 - 6 + date.getMonth() + 1) + "-";
    }
  }

  if (TIME == "YTD") {
    M = "01" + "-";
    D = "01";
  }

  if (TIME == "1Y") {
    Y = date.getFullYear() - 1 + "-";
  }

  if (TIME == "3Y") {
    Y = date.getFullYear() - 3 + "-";
  }

  let result = "";
  if (TIME == "MAX") {
    result = null;
  } else {
    result = Y + M + D;
  }

  return result;
}

//  判断等级
export function fnGetLevelName() {
  return window.sessionStorage.getItem("levelName");
}

// 判断几位小数
export function fnGetMuchDecimals(val) {
  return (parseInt(Math.abs(val)) + "").length >= 2 ? 1 : 2;
}

// 返回表格样式
export function fnReturnTableStyle(val) {
  let styleBind =
    "background:#343742;fontSize:16px;color:#dadada;fontWeight: 700; border-bottom: 1px solid #4c4d5d; border-top: 1px solid #4c4d5f;";
  let styleBind_light =
    // "background:#f7f7f7;fontSize:16px;color:#707070;fontWeight: 700; border-bottom: 3px solid #decfcf; border-top: 2px solid #bda0a0;";
    "background:#f7f7f7;fontSize:16px;color:#707070;fontWeight: 700; border-bottom: 1px solid #dcddde; border-top: 1px solid #dcddde;";

  return val ? styleBind : styleBind_light;
  // return "background:#343742;fontSize:16px;color:#dadada;fontWeight: 700; border-bottom: 3px solid #4c4d5d; border-top: 2px solid #4c4d5f;";
}

// // 返回表格样式
// export function fnReturnTableStyle_collect(val){
//   let styleBind =
//         "background:#343742;fontSize:16px;color:#dadada;fontWeight: 700; border-bottom: 3px solid #4c4d5d; border-top: 2px solid #4c4d5f;";
//       let styleBind_light =
//         "background:#f7f7f7;fontSize:16px;color:#707070;fontWeight: 700; border-bottom: 3px solid #decfcf; border-top: 2px solid #bda0a0;";

//       return val?styleBind:styleBind_light;
//   // return "background:#343742;fontSize:16px;color:#dadada;fontWeight: 700; border-bottom: 3px solid #4c4d5d; border-top: 2px solid #4c4d5f;";
// }

// 判断报告期是否范围合理
export function fnJudgePeriodRational(start, end, DATA) {
  let start_val = DATA.findIndex((node) => {
    return node.value == start;
  });

  let end_val = DATA.findIndex((node) => {
    return node.value == end;
  });

  return start_val - end_val + 1 > 6 ? false : true;
}

// 判断报告期 end 不能小于 start
export function fnJudgePeriodOrder(start, end, DATA) {
  let start_val = DATA.findIndex((node) => {
    return node.value == start;
  });

  let end_val = DATA.findIndex((node) => {
    return node.value == end;
  });

  return start_val < end_val ? false : true;
}


// 跳转个股
export function fnSkipIndividualShare(arr, CODELIST) {

  console.log(arr, '利用一份')

  let name = arr[0];
  CODELIST.forEach((node) => {
    if (node.reitsName == name) {
      name = node.reitsNameSe;
    }
  });


  window.sessionStorage.setItem("CURRENTCODE", arr[1]);
  window.sessionStorage.setItem("CURRENTNAME", name);

  console.log(name, '逆水寒啊啊啊');
  console.log(arr, '逆水寒');
  this.$eventBus.$emit("getIndividualShare");

  this.saveNavState({ path: "individualShare" });
  this.$router.push("/individualShare");
  location.reload();



}


// 初始化benchmark 级联

// 下载背景色
export function fnDownloadBgc(val) {
  return val ? '#35373b' : '#fff'
}

// 颜色转换
export function hexToRgba(hex, opacity) {
  let rgba = 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' +
    parseInt('0x' + hex.slice(3, 5)) + ',' +
    parseInt('0x' + hex.slice(5, 7)) + ',' +
    (opacity || "1") + ')'
  return rgba
}



// 根据dom导出表格
export function exportToExcel(idSelector, fileName, titleNum = 1) {
  // 设置导出的内容是否只做解析，不进行格式转换     false：要解析， true:不解析
  const xlsxParam = { raw: true }
  let table = document.querySelector(idSelector).cloneNode(true);
  // 因为element-ui的表格的fixed属性导致多出一个table，会下载重复内容，这里删除掉
  if (table.querySelector('.el-table__fixed-right'))
    table.removeChild(table.querySelector('.el-table__fixed-right'));
  if (table.querySelector('.el-table__fixed'))
    table.removeChild(table.querySelector('.el-table__fixed'));
  const wb = XLSX.utils.table_to_book(table, xlsxParam)
  let range = XLSX.utils.decode_range(wb.Sheets['Sheet1']['!ref']);
  let cWidth = [];
  for (let C = range.s.c; C < range.e.c; ++C) {   //SHEET列
    let len = 100; //默认列宽
    let len_max = 400; //最大列宽
    for (let R = range.s.r; R <= range.e.r; ++R) {  //SHEET行
      let cell = { c: C, r: R };                    //二维 列行确定一个单元格
      let cell_ref = XLSX.utils.encode_cell(cell);  //单元格 A1、A2
      if (wb.Sheets['Sheet1'][cell_ref]) {
        // if (R == 0){
        if (R < titleNum) {
          wb.Sheets['Sheet1'][cell_ref].s = {  //设置第一行单元格的样式 style
            alignment: {
              horizontal: 'center',
              vertical: 'center',
            },
          };
        } else {
          wb.Sheets['Sheet1'][cell_ref].s = {
            alignment: {
              horizontal: 'center',
              vertical: 'center',
            },
          };
        }
        //动态自适应：计算列宽
        let va = JSON.parse(JSON.stringify(wb.Sheets['Sheet1'][cell_ref].v));
        var card1 = JSON.parse(JSON.stringify(va)).match(/[\u4e00-\u9fa5]/g); //匹配中文
        var card11 = "";
        if (card1) {
          card11 = card1.join("")
        }
        var card2 = JSON.parse(JSON.stringify(va)).replace(/([^\u0000-\u00FF])/g, "");  //剔除中文
        let st = 0;
        if (card11) {
          // st += card11.length * 16  //中文字节码长度
          st += card11.length * 20  //中文字节码长度
        }
        if (card2) {
          // st += card2.length * 8  //非中文字节码长度
          st += card2.length * 10  //非中文字节码长度
        }
        if (st > len) {
          len = st;
        }
      }
    }
    if (len > len_max) {//最大宽度
      len = len_max;
    }
    cWidth.push({ 'wpx': len });     //列宽
  }
  wb.Sheets['Sheet1']['!cols'] = cWidth
  const wbout = XLSXStyle.write(wb, { bookType: 'xlsx', bookSST: false, type: 'binary' })
  try {
    saveAs(new Blob([s2ab(wbout)], { type: '' }), `${fileName}.xlsx`)
  } catch (e) {
    if (typeof console !== 'undefined') {
      console.log(e, wbout)
    }
  }
  return wbout
}

// 时间格式转化
export function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要+1  
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}/${month}/${day}`;
}

let version = "v1.5";
let version_date = "2024/10/08";
// 存储版本更新提示
export function storageUpdate(checked) {
  if(checked){
    localStorage.setItem(version,1);
  }else{
    localStorage.setItem("login_"+version,formatDate(new Date()));
  }
}
// 判断是否显示版本提示
export function judgeUpdate() {
  var this_day = formatDate(new Date());
  var has_version = localStorage.getItem(version);
  var login_version = localStorage.getItem("login_"+version);
  if(has_version){
    return false;
  }
  if(login_version&&this_day==login_version){
    return false;
  }
  if((new Date(this_day).getTime()-new Date(version_date).getTime())/(3600*24*1000)>=7){
    return false;
  }
  return true;
}
