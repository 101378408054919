<template>
  <div id="rootchart" class="login_container" >
    <video
      class="bg_cover"
      autoplay
      loop
      muted
      src="https://conghua-pics.obs.cn-north-4.myhuaweicloud.com/database/static/poster.mp4"
    ></video>
    <div class="bg_cover_shadow"></div>
    <div class="top">
      <img src="@/assets/logo.png" alt="" />
    </div>

    <div class="bottom">
      <div class="left_case">
        <div class="big_title">不动产金融领域的科技洞见者</div>
        <div class="desc">
          在泛不动产时代新一轮的基金化机会中，通过深度理解不
          同底层业务模式，跨越一二级市场认知与投研能力，前沿
          高效的综合数据与科学方法<br />
          <br />
          为另类投资领域的一二级广泛参与者，提供富有洞见、深
          掘底层逻辑的投资助力，穿越不确定性，把握投资机遇。
        </div>
      </div>
      <div class="loginArea">
        <transition name="slide-fade">
          <div class="loginArea_main">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="登录" name="first">
                <el-form
                  ref="loginFormRef"
                  :model="loginForm"
                  :rules="loginFormRules"
                  label-width="0px"
                  class="login_form"
                >
                  <el-form-item prop="username">
                    <el-input
                      v-model="loginForm.username"
                      placeholder="请输入账号"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="password">
                    <el-input
                      v-model="loginForm.password"
                      placeholder="请输入密码"
                      show-password
                    ></el-input>
                  </el-form-item>
                </el-form>

                <div class="verificationCode">
                  <span>
                    <el-checkbox v-model="checked">记住密码</el-checkbox>
                  </span>
                  <span> 验证码登录 </span>
                </div>
                <el-button class="loginBTN" @click="Login" type="primary"
                  >Let's Connect</el-button
                >
              </el-tab-pane>

              <el-tab-pane label="注册" name="second" style="color: white"
                >注册</el-tab-pane
              >
            </el-tabs>
          </div>
        </transition>
        <div class="loginInfo">
          <span>登录即代表同意</span> <span>《隐私权政策》</span>| 忘记密码？
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      screenRatio: 1,
      flag: false,

      codeImgSrc: "",
      codeImgResult: "",
      url: "",
      token: "",
      // 这是登录表单的数据绑定对象
      loginForm: {
        username: "",
        password: "",
      },
      verifiCode: "",
      // 这是表单的验证规则对象
      loginFormRules: {
        // 验证用户名是否合法
        username: [
          {
            required: true,
            message: "请输入登录名称",
            trigger: "blur",
          },
          // {
          //   min: 3,
          //   max: 10,
          //   message: "长度在 3 到 10 个字符",
          //   trigger: "blur",
          // },
        ],
        // 验证密码是否合法
        password: [
          {
            required: true,
            message: "请输入登录密码",
            trigger: "blur",
          },
          // {
          //   min: 6,
          //   max: 15,
          //   message: "长度在 6 到 15 个字符",
          //   trigger: "blur",
          // },
        ],
      },

      stateJudage: this.$route.query.data,
      checked: false,
      activeName: "second",

      OFFSETWIDTH: 300,
      OFFSETHEIGHT: 200,
      ROOTFONTFIZE: 0,
    };
  },
  beforeCreate() {},
  created() {
    // this.getCsrfToken()
    this.fnGetUnits();

    this.fnGetRatio();

    // this.getCodeImg()
    let obj = {
      name: "first",
    };
    this.handleClick(obj);
  },
  mounted() {
    this.fnGetWidth();
    window.addEventListener("resize", (event) => {
      console.log("浏览器窗口大小已改变，可能发生了缩放操作");
      // 响应缩放的代码
      //   console.log(event.target.devicePixelRatio);
      this.fnGetWidth(event.target.devicePixelRatio);
    });
  },
  methods: {
    // 获取当前屏幕宽度
    fnGetWidth(ratio) {
      var element = document.getElementById("rootchart");

      let ROOTFONTFIZE = element.offsetHeight / 1080;
      //   ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      //   this.ROOTFONTFIZE = ROOTFONTFIZE / 10;
      this.ROOTFONTFIZE = 140 / ROOTFONTFIZE;
      if (!ratio) {
        return;
      }
      if (ratio != 1) {
        this.ROOTFONTFIZE = this.ROOTFONTFIZE / ratio;
        console.log("fnGetWidth", this.ROOTFONTFIZE);
      }
    },

    //获取屏幕缩放比例
    fnGetRatio() {
      function getRatio() {
        var ratio = 0;
        var screen = window.screen;
        var ua = navigator.userAgent.toLowerCase();
        if (window.devicePixelRatio !== undefined) {
          ratio = window.devicePixelRatio;
        } else if (~ua.indexOf("msie")) {
          if (screen.deviceXDPI && screen.logicalXDPI) {
            ratio = screen.deviceXDPI / screen.logicalXDPI;
          }
        } else if (
          window.outerWidth !== undefined &&
          window.innerWidth !== undefined
        ) {
          ratio = window.outerWidth / window.innerWidth;
        }
        if (ratio) {
          ratio = Math.round(ratio * 100);
        }
        return ratio;
      }

      this.screenRatio = getRatio();

      localStorage.setItem("screenRatio", this.screenRatio);
    },

    handleClick(e) {
      this.activeName = e.name;
    },

    async Login() {
      // window.sessionStorage.setItem("activePath", "dashboard");
      // this.$router.push("/home");
      // return;

      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;
        let username = this.loginForm.username;
        let password = this.loginForm.password;

        const { data } = await this.$https.post(
          // "auth/customer/login?userName=" +
          "api/auth/customer/login?userName=" +
            // "auth/customer/login?userName=" +

            username +
            "&password=" +
            password
        );

        if (data.code !== 200) return this.$message.error(data.msg);
        this.$message.success("欢迎登录葱花投研！");
        window.sessionStorage.setItem("activePath", "dashboard");
        let token = data.data.access_token;
        window.sessionStorage.setItem("token", token);

        this.$router.push("/home");

        // 1. 将登录成功之后的 token，保存到客户端的 sessionStorage 中
        //   1.1 项目中出了登录之外的其他API接口，必须在登录之后才能访问
        //   1.2 token 只应在当前网站打开期间生效，所以将 token 保存在 sessionStorage 中
        // window.sessionStorage.setItem('token', res.data.token)
        // 2. 通过编程式导航跳转到后台主页，路由地址是 /home
      });
      // const res = await this.$https.post('https://api.rainben.cn/status?_ajax=1')
      // if (res.status !== 200) return this.$message.error(res.statusText)
      // window.location.href = res.data.url
    },

    // 获取财务 - 单位
    async fnGetUnits() {
      const res = await this.$https.get("api/conghua/finTableAssumption/list");

      let data = res.data;

      localStorage.setItem("UNITS", JSON.stringify(data));
    },
  },
  computed: {
    cssVars() {
      return {
        "--OFFSETWIDTH": this.OFFSETWIDTH + "px",
        "--OFFSETHEIGHT": this.OFFSETHEIGHT + "px",
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
        // "--OFFSETHEIGHT": this.OFFSETHEIGHT,
      };
    },
  },
};
</script>

<style lang="less" scoped>
@vh:1/10.8vh;
.login_container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .bg_cover {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    object-fit: cover;
  }
  .bg_cover_shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .top {
    padding-top: @vh * (26 );
    padding-left: @vh * (32 );
    img {
      width: @vh * (142 );
      height: auto;
    }
  }
  .bottom {
    width: 90%;
    display: flex;
    justify-content: center;
    margin-top: @vh * (97 );
    .left_case {
      width: @vh * (750 );
      margin-right: @vh * (72 );
      .big_title {
        margin-top: @vh * (81 );
        margin-bottom: @vh * (110 );
        font-size: @vh * (57 );
        color: #ffffff;
        line-height: @vh * (75 );
      }
      .desc {
        padding-left: @vh * (27 );
        font-size: @vh * (24 );
        color: #ffffff;
        line-height: @vh * (53 );
        border-left: 2px solid #b5e7e8;
        letter-spacing: @vh * (4 );
      }
    }
    .loginArea {
      margin-top: @vh * (81 );
      width: @vh * (532 );
      min-height: @vh * (532 );
      padding-bottom: @vh * (20 );
      //   background-color: rgba(30, 30, 30, 0.5);
      border-radius: @vh * (31 );
      opacity: 1;
      //   border: 1px solid #0e0509;
      margin-left: @vh * (180 );
      // margin-top: @vh * (108 ) ;
      // margin-bottom: @vh * (108 ) ;
      box-sizing: border-box;
      .loginArea_main {
        padding-top: @vh * (33 );
        padding-left: @vh * (39 );
        width: @vh * (443 );
        min-height: @vh * (449 );
        background-color: rgba(30, 30, 30, 0.6);
        border: 1px solid #909090;
        border-radius: @vh * (20 );
      }
      .el-tabs {
        margin-right: @vh * (2 );
      }

      .el-icon-arrow-right {
        color: #fff;
        font-size: @vh * (12 );
        margin-left: @vh * (5 );
      }

      .login_passwordLogin {
        margin-top: @vh * (23 );
        font-size: @vh * (14 );
        font-weight: 400;
        letter-spacing: 0px;
        line-height: @vh * (14 );
        color: rgba(0, 0, 0, 0.8);
      }

      .password_area {
        display: flex;
        justify-content: space-between;
      }

      .password_area span:nth-child(1) {
        font-size: @vh * (16 );
        font-weight: 400;
        letter-spacing: 0px;
        line-height: @vh * (20 );
        color: rgba(155, 161, 168, 1);
      }

      .password_area span:nth-child(2) {
        font-size: @vh * (16 );
        font-weight: 400;
        letter-spacing: 0px;
        line-height: @vh * (20 );
        color: rgba(63, 159, 255, 1);
      }

      .btns {
        margin-top: @vh * (28 );
      }

      .agree {
        font-size: @vh * (14 );
        font-weight: 400;
        letter-spacing: 0;
        color: rgba(194, 199, 204, 1);
        margin-top: @vh * (26 );
      }

      .el-tabs {
        background-color: transparent;
      }

      ::v-deep .el-tabs__item {
        height: @vh * (50 );
        font-size: @vh * (29 );
        font-family: PingFangSC, PingFang SC;
        font-weight: bold;
        color: #868686;
      }

      ::v-deep .el-tabs__item:hover {
        color: #f0f0f0 !important;
      }

      ::v-deep .el-tabs__item.is-active {
        color: #f0f0f0 !important;
      }

      /*去掉tabs底部的下划线*/
      ::v-deep .el-tabs__nav-wrap::after {
        position: static !important;
      }

      /*去掉切换时el-tab-pane底部的蓝色下划线*/
      ::v-deep .el-tabs__active-bar {
        height: @vh * (5 );
        background-color: #ffffff !important;
        border-radius: @vh * (5 ) !important;
      }

      .login_form {
        padding-right: @vh * (20 );
        padding-left: 0 !important;
        margin-bottom: 0 !important;
      }
      /deep/ .el-form-item__content {
        width: @vh * (370 );
      }
      ::v-deep .el-input__inner {
        width: @vh * (380 );
        height: @vh * (55 );
        border-radius: @vh * (13 );
        margin-left: 0 !important;
        border: 1px solid #f3f2f2;
        background-color: transparent !important;
        font-size: @vh * (22 );
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #dededf !important;
      }

      .el-input__icon:after {
        // width: 50px;
      }

      .verificationCode {
        margin-top: 0 !important;
        padding-right: @vh * (65 );
        display: flex;
        justify-content: space-between;
        margin-bottom: @vh * (40 );
      }
      .el-checkbox {
        color: #fdfdfd;
      }
      //   /deep/ .el-checkbox__inner{
      //     background-color: transparent !important;
      //   }
      ::v-deep .el-checkbox__label {
        font-size: @vh * (16 );
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
      }

      ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #409eff !important;
      }

      .verificationCode span:nth-child(2) {
        font-size: @vh * (16 );
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #6af7f9;
      }

      ::v-deep .el-button {
        margin-left: 10*@vh ;
        width: @vh * (415 )*0.91 !important;
        height: @vh * (84 )*0.91 !important;
        font-size: @vh * (28 );
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        border-radius: @vh * (12 );
        background: linear-gradient(to right, #017097 30%, #64e6e8 70%);
        border: none;
      }

      .loginInfo {
        font-size: @vh * (16 );
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #f7f7f7;
        text-align: center;
        padding-top: @vh * (30 );
      }

      .loginInfo span:nth-child(1) {
        color: #f7f7f7;
        font-weight: 700;
      }

      .loginInfo span:nth-child(2) {
        color: #63e1e3;
        font-weight: 700;
      }
      /deep/.el-tabs__header {
        margin-left: @vh * (22 );
        margin-bottom: @vh * (30 );
      }
      /deep/ .el-form-item {
        margin-bottom: @vh * (30 );
      }
    }
  }
}
</style>
