<template>
  <div class="container" id="rootchart" :style="cssVars">
    <div class="CARD" v-show="true">
      <!-- <div class="CARD_tit">鹏华深圳能源 REITs  | 180401.SZ</div> -->
      <div class="CARD_tit">
        {{ STOCKNAME }} | <span class="CARD_tit_code">{{ CODE }}</span>
      </div>
      <div class="choose_area">
        <div>
          <span>
            <CCascader
              class="CCascader_style"
              :needarr="true"
              :checkStrictly="true"
              @CCascader_select_val="fnCCascader"
              :default="Folder_L3_default"
              :options="Folder_L3"
              >公告类型</CCascader
            >
          </span>

          <!-- <span>
             <CSelect
              ref="CSelect_1"
              @CSelect_select_val="fnSelect1"
              :clearable="true"
              :options="Folder_L1"
              :default="default_Folder_L1"
              ></CSelect
            > 

          </span> -->
        </div>

        <!-- <div>
          <span>公告内容</span>
          <span>
               <CSelect
              ref="CSelect_2"
              @CSelect_select_val="fnSelect2"
              :clearable="true"
              :options="Folder_L2"
              :default="default_Folder_L2"
              ></CSelect
            > 
          </span>
        </div> -->

        <div>
          <!-- <CTimeButtons /> -->
        </div>
      </div>
      <div class="CARD_body">
        <div class="announcement">
          <div class="Announcement_item" v-for="(node, idx) in dFileFolderList" :key="idx">
            <div class="Announcement_item_left">
              <!-- <div class="Announcement_item_left_first">定期公告</div> -->
              <div @click="fnClick(node)" class="Announcement_item_left_two">
                {{ node.web_show_str }}
              </div>
              <div class="Announcement_item_left_three">
                {{ node.pub_date }}
              </div>
            </div>
            <div class="Announcement_item_right">
              <span
                >{{ node.folder_l1 | folderL1_Filter }} -
                {{ node.folder_l2 | folderL2_Filter }}</span
              >
              <span @click="fnClick2(node)" class="iconfont icon-pdf"></span>
            </div>
          </div>
        </div>

        <div class="checkAll">
          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="size"
            layout="total,  prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CSelects from '@/components/Basic/CSelects';
import CSelect from '@/components/Basic/CSelect';
import CCategoryRadio from '@/components/Basic/CCategoryRadio';
import CButtons from '@/components/Basic/CButtons';
import CTimeButtons from '@/components/Basic/CTimeButtons';
import CButtonsSeparate from '@/components/Basic/CButtons_separate';
import CCascader from '@/components/Basic/CCascader';

export default {
  data() {
    return {
      ROOTFONTFIZE: 0,

      CODE: '',
      STOCKNAME: '',

      dBtn1: ['3M', '6M', '1Y', 'YTD', 'MAX'],
      activeBtn1: '3M',

      // new
      dFileFolderList: [],
      FINALLYDFileFolderList: [],
      size: 0,
      total: 0,
      Folder_L1: this.$store.state.Folder_L1,
      Folder_L2: this.$store.state.Folder_L2,
      Folder_L3: this.$store.state.Folder_L3,
      FINALLY_Folder_L2: this.$store.state.Folder_L2,
      Folder_L3_default: 'all',

      default_Folder_L1: '',
      default_Folder_L2: '',

      currentPage: 1,
    };
  },
  created() {
    // this.CODE = window.sessionStorage.getItem("CURRENTCODE")
    // this.STOCKNAME = window.sessionStorage.getItem("CURRENTNAME")
    // this.fnGetFileFolderList();
    // this.FnInit()
  },
  mounted() {
    this.fnGetWidth();
  },
  methods: {
    fnGetWidth() {
      this.ROOTFONTFIZE = localStorage.getItem('ROOTFONTFIZE');
    },

    FnInit() {
      this.CODE = window.sessionStorage.getItem('CURRENTCODE');
      this.STOCKNAME = window.sessionStorage.getItem('CURRENTNAME');

      this.default_Folder_L1 = 'ALL';
      this.default_Folder_L2 = 'all';
      this.fnGetFileFolderList();
    },
    // =====================================================================================================
    // 1.
    async fnGetFileFolderList(val) {
      let page = val ? val : '1';
      let code = this.CODE;
      let folderL1 = this.default_Folder_L1 == 'ALL' ? '' : this.default_Folder_L1;
      let folderL2 = this.default_Folder_L2 == 'all' ? '' : this.default_Folder_L2;

      const { data } = await this.$https.post(
        '/api/fileFolderList' + '?code=' + code + '&folderL1=' + folderL1 + '&folderL2=' + folderL2
      );

      if (data.success !== true) return this.$message.error(data.msg);
      // this.dFileFolderList = data.data;

      this.size = 20;
      this.currentPage = 1;

      this.FINALLYDFileFolderList = data.data;

      this.fnArraySplice();
    },

    // // 数组切分
    fnArraySplice() {
      let val1 = (Number(this.currentPage) - 1) * this.size;
      let val2 = Number(this.currentPage) * this.size;
      this.dFileFolderList = this.FINALLYDFileFolderList.slice(val1, val2);
      this.total = this.FINALLYDFileFolderList.length;
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      this.fnArraySplice();
    },

    fnClick(node) {
      let url = node.url;
      if (url.includes('.pdf')) {
        window.open(url);
      } else {
        console.log(false);
        this.fnDownloadPdf(url);
        // window.open(url)
      }
    },

    fnClick2(node) {
      let url = node.url;
      if (url.includes('.pdf')) {
        window.open(url);
      } else {
        console.log(false);
        this.fnDownloadPdf(url);
        // window.open(url)
      }

      // return
      // let name = node.reitsName + ".pdf";
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", name);
      // link.click();
    },

    async fnDownloadPdf(data) {
      let params = {
        proxyUrl: data,
      };

      const res = await this.$https.post('api/common/query/download', params, {
        responseType: 'blob',
      });
      console.log(res, 'res123123');
      let headers_list = res.headers['content-disposition'].split(';');
      var link_url = '';
      headers_list.forEach((item) => {
        if (item.indexOf('filename=') != -1) {
          link_url = item.replace(' ', '').replace('filename=', '');
        }
      });
      let fileName = decodeURIComponent(link_url);
      console.log(fileName, '12312');
      const elink = document.createElement('a');
      elink.style.display = 'none';
      const blob = new Blob([res.data], { type: 'application/x-msdownload' });
      let url = URL.createObjectURL(blob);
      elink.href = url;
      let s = decodeURIComponent(escape(fileName)).replace(/^\"|\"$/g, '');
      elink.download = s;
      document.body.appendChild(elink);
      elink.click();
    },
    fnCCascader(val) {
      this.default_Folder_L1 = val[0];
      this.default_Folder_L2 = val[1] ? val[1] : '';
      this.fnGetFileFolderList();
    },

    fnSelect1(val) {
      this.default_Folder_L1 = val;
      this.fnGetFileFolderList();

      // if(val){
      //     this.dFileFolderList = this.FINALLYDFileFolderList.filter(node=>{
      //          return node.folderL1 == val
      //     })
      //     // 设置L2
      //     if(val == 'Periodic_Report'){
      //         this.Folder_L2 = this.FINALLY_Folder_L2.filter(node=>{
      //                 return node.label == '季报' || node.label == '年报' || node.label == '中期报告'
      //         })
      //     }

      //      if(val == 'Issuance'){
      //         this.Folder_L2 = this.FINALLY_Folder_L2.filter(node=>{
      //                 return node.label == 'IPO' || node.label == 'IPO附件' || node.label == '扩募' || node.label == '扩募附件'
      //         })
      //     }

      //      if(val == 'REITs_Assets'){
      //         this.Folder_L2 = this.FINALLY_Folder_L2.filter(node=>{
      //                 return node.label == '评估报告' || node.label == '运营数据'
      //         })
      //     }

      //      if(val == 'Fund_Operation'){
      //         this.Folder_L2 = this.FINALLY_Folder_L2.filter(node=>{
      //                 return node.label == '收益分配公告' || node.label == '限售及解除限售公告'  || node.label == '投资者关系活动公告' || node.label == '风险提示公告' || node.label == '停牌/复牌公告' || node.label == '增持及回购公告' || node.label == '做市商调整公告' || node.label == '提示性公告'
      //         })
      //     }

      //      if(val == 'Others'){
      //         this.Folder_L2 = []
      //     }

      //   }else{
      //      this.dFileFolderList = this.FINALLYDFileFolderList.filter(node=>{
      //        return node.folderL1 !== val
      //      })

      //      this.Folder_L2 = this.FINALLY_Folder_L2
      //   }
    },
    fnSelect2(val) {
      this.default_Folder_L2 = val;
      this.fnGetFileFolderList();
      return;

      if (val) {
        this.dFileFolderList = this.FINALLYDFileFolderList.filter((node) => {
          return node.folderL2 == val;
        });
      } else {
        this.dFileFolderList = this.FINALLYDFileFolderList.filter((node) => {
          return node.folderL2 !== val;
        });
      }
    },
  },
  filters: {
    folderL1_Filter: function (val) {
      let Folder_L1 = {
        定期公告: 'Periodic_Report',
        募集发行: 'Issuance',
        底层资产公告: 'REITs_Assets',
        基金运营公告: 'Fund_Operation',
        其他公告: 'Others',
      };
      for (let i in Folder_L1) {
        if (Folder_L1[i] == val) {
          return i;
        }
      }
    },
    folderL2_Filter: function (val) {
      let Folder_L1 = {
        季报: 'Quarterly_report',
        年报: 'Annual_report',
        中期: 'Midterm_report',
        IPO: 'IPO',
        IPO附件: 'IPO_appendix',
        扩募: 'SO',
        扩募附件: 'SO_appendix',
        评估报告: 'Valuation_report',
        运营数据: 'Operational_data',
        收益分配公告: 'Div_notice',
        限售及解除限售公告: 'Restrictions',
        投资者关系活动公告: 'IR',
        风险提示公告: 'Risk_Warning',
        '停牌/复牌公告': 'Suspension_Resumption',
        增持及回购公告: 'Accumulation_Repurchase',
        做市商调整公告: 'Market_Maker',
        提示性公告: 'Informative_Notice',
      };
      for (let i in Folder_L1) {
        if (Folder_L1[i] == val) {
          return i;
        }
      }
    },
  },
  computed: {
    cssVars() {
      return {
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
      };
    },
  },

  components: {
    CSelect,
    CSelects,
    CCategoryRadio,
    CButtons,
    CTimeButtons,
    CButtonsSeparate,
    CCascader,
  },
};
</script>

<style lang="less" scoped>
.container {
}

.CARD {
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: var(--charts_bgc);
  padding: calc(var(--ROOTFONTFIZE) * (15 / 144)) 0px;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (25 / 144));
}

.CARD:last-child {
  margin-bottom: 0;
}

.CARD_tit {
  padding-left: calc(var(--ROOTFONTFIZE) * (22 / 144));
  // border-left: 5px solid rgba(21, 115, 254, 1);
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (30 / 144));
  // height: 20px;
  color: var(--chart_title);
}

.CARD_tit2 {
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: rgba(255, 255, 255, 1);
  margin-top: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.CARD_body {
  padding: 0 calc(var(--ROOTFONTFIZE) * (45 / 144));
}

.CARD_body_tabs {
  padding: 0px calc(var(--ROOTFONTFIZE) * (30 / 144));
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CARD_body_tabs div {
  display: flex;
  align-items: center;
}

.el-icon-download {
  margin-left: calc(var(--ROOTFONTFIZE) * (45 / 144));
}

.CARD_body_btn_sep {
  margin-top: calc(var(--ROOTFONTFIZE) * (38 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (15 / 144));
}

.marketValue {
  margin-top: calc(var(--ROOTFONTFIZE) * (35 / 144));
}

.marketValue .value span:nth-child(1) {
  font-size: calc(var(--ROOTFONTFIZE) * (36 / 144));
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(255, 255, 255, 1);
}

.marketValue .value span:nth-child(2) {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(49, 208, 104, 1);
  margin-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.marketValue .time {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: calc(var(--ROOTFONTFIZE) * (16 / 144));
  color: rgba(255, 255, 255, 1);
  margin-top: calc(var(--ROOTFONTFIZE) * (18 / 144));
}

.CARD_body_echart,
.CARD_body_table {
  height: calc(var(--ROOTFONTFIZE) * (245 / 144));
  background: rgba(0, 0, 0, 0.1);
  border: 0.2px solid rgba(135, 135, 135, 1);
  border-radius: calc(var(--ROOTFONTFIZE) * (5 / 144));
}

.choose_area {
  margin-top: calc(var(--ROOTFONTFIZE) * (24 / 144));
  display: flex;
  padding-left: calc(var(--ROOTFONTFIZE) * (45 / 144));
}

.choose_area div {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(255, 255, 255, 1);

  display: flex;
  align-items: center;
  margin-right: calc(var(--ROOTFONTFIZE) * (35 / 144));
}

.charts_area {
  display: flex;
}

.left {
  flex: 1;
  margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.right {
  flex: 1;
}

.announcement {
  margin-top: calc(var(--ROOTFONTFIZE) * (36 / 144));
}

.Announcement_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (15 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (90 / 144));
  border-bottom: 1px solid rgba(118, 118, 118, 1);
}

.Announcement_item_left {
  flex: 10;
}

.Announcement_item_left_first {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(255, 255, 255, 1);
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.Announcement_item_left_two {
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (24 / 144));
  color: var(--item_color);
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.Announcement_item_left_two:hover {
  cursor: pointer;
}

.Announcement_item_left_three {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: var(--item_date_color);
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.icon-pdf {
  color: var(--item_color);
  font-size: calc(var(--ROOTFONTFIZE) * (24 / 144));
}

.checkAll {
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
  font-weight: 400;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (26.06 / 144));
  color: rgba(255, 255, 255, 1);
}

.HEIGHT_396 {
  height: calc(var(--ROOTFONTFIZE) * (396 / 144)) !important;
}

.HEIGHT_664 {
  height: calc(var(--ROOTFONTFIZE) * (664 / 144)) !important;
}

.HEIGHT_370 {
  height: calc(var(--ROOTFONTFIZE) * (370 / 144)) !important;
}

.HEIGHT_471 {
  height: calc(var(--ROOTFONTFIZE) * (471 / 144)) !important;
}

.HEIGHT_396 {
  height: calc(var(--ROOTFONTFIZE) * (396 / 144)) !important;
}

.Announcement_item_right {
  // width: 25%;
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--Btn-color2);
}

.icon-pdf:hover {
  cursor: pointer;
}

// 分页 - start
// .el-pagination {
//   text-align: center;
//   margin: calc(var(--ROOTFONTFIZE) * (30 / 144)) 0;
// }

// ::v-deep .el-pagination__total,
// ::v-deep .el-pagination__jump {
//   color: var(--pagination_jump_color) !important;
//   font-size: calc(var(--ROOTFONTFIZE) * (16 / 144)) !important;
// }

// ::v-deep .el-pagination .btn-prev,
// ::v-deep .el-pagination .btn-next {
//   background-color: transparent;
//   color: var(--pagination_jump_color) !important;
//   font-size: calc(var(--ROOTFONTFIZE) * (16 / 144)) !important;
// }

// ::v-deep .el-pager li {
//   background-color: var(--pagination_li_color) !important;
//   color: var(--pagination_jump_color) !important;
//   font-size: calc(var(--ROOTFONTFIZE) * (16 / 144)) !important;
// }

// ::v-deep .el-pager li.active {
//   background-color: var(--primary-color) !important;
//   color: #fff !important;
// }

// ::v-deep .el-pagination__editor.el-input .el-input__inner {
//   background-color: var(--pagination_li_color) !important;
//   color: var(--pagination_input_color);
//   border: 1px solid var(--pagination_brc);
// }

// 分页 - end

/* // 分页 - start */

.el-pagination {
  text-align: center;
  margin-top: calc(var(--ROOTFONTFIZE) * (50 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

::v-deep .el-pagination__total,
::v-deep .el-pagination__jump {
  color: var(--pagination_editor_color) !important;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144)) !important;
}

::v-deep .el-pagination .btn-prev,
::v-deep .el-pagination .btn-next {
  background-color: transparent;
  color: var(--pagination_prev_color) !important;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144)) !important;
}

::v-deep .el-pager li {
  background-color: var(--pagination_li_bgc) !important;
  color: var(--pagination_li_color) !important;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144)) !important;
}

::v-deep .el-pager li.active {
  background-color: var(--primary-color) !important;
  color: var(--pagination_li_active_color) !important;
}

::v-deep .el-pagination__editor.el-input .el-input__inner {
  background-color: var(--pagination_editor_bgc) !important;
  color: var(--pagination_editor_color);
  border: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid var(--pagination_editor_brc);
}

::v-deep .el-timeline-item__timestamp {
  position: absolute;
  left: calc(var(--ROOTFONTFIZE) * (-150 / 144));
  top: 0;
  color: var(--pagination_timestamp_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
}
/* // 分页 - end */
</style>
