<template>
  <div class="shadow" v-if="show">
    <div class="close_b" @click="close"></div>
    <div class="case">
      <div class="big_title">
        更新说明 2024-1
        <div class="close" @click="close">x</div>
      </div>
      <div class="time">发布时间：2024/10/09</div>
      <div class="content">
        各位葱花投研的用户，本产品于近期根据大家的使用习惯，与日常反应的问题，进行了第一轮的前期Bug修复及对应的功能优化已经完成，目前发布版本为v1.5版本更新（不是完整的v2.0更新版本），本轮更新的主要调整部分如下：<br />
        <span>大类及行业部分：</span>
        <div class="b_t">1.Dashboard大类资产板块：</div>
        <div class="s_t">
          1.1 修复了部分数据显示bug;<br />
          <span>1.2 增加了DR007数据对比;</span>
        </div>
        <div class="b_t">2.市场动态板块：</div>
        <div class="s_t">
          2.1 增加了指数部分<span>（大类+细分）的下载功能</span>；<br />
          2.2 收益分析增加了数据截至日期显示；<br />
          2.3 年化收益率及波动率对比，调整为：<span>行业+个股平行对比</span>；<br />
          2.4 风险分析，<span>增加 REITs行业类别与中证REITs指数自身对比，</span
          >以了解板块和个券的beta水平；<br />
          2.5 修复了其他细节数据显示bug;
        </div>
        <div class="b_t">3.财报分析板块：</div>
        <div class="s_t">3.1 调整了图形显示顺序，从最早年份到最新年份，从左到右顺序调整；</div>
        <div class="b_t">4.估值分析板块<span>（重大更新）</span>：</div>
        <div class="s_t">
          4.1 <span>增加了新指标：隐含Cap Rate 、PAFFO口径数据；</span><br />
          4.2 <span>新数据同步增加：历史估值、估值bands及平行估值分析；</span><br />
          4.3 <span> IRR/NPV: </span><br />
          <span>添加了IRR/NPV切换模式，可以切换NPV估值结果数据</span><br />
          <span>增加了IRR/NPV都可以多行业，多选模式，同一数据可以平行多图对比；</span><br />
          <span
            >增加了增速切换方式，原本为默认行业增速回填，目前模式：行业增速、个股增速、无增速；多档切换（v2.0后可自定义）</span
          ><br />
          <span>NPV模式增加，自设要求回报率切换，可在4-6%之间的要求回报，切换NPV结果</span><br />
        </div>
        <div class="b_t">
          <span>个股部分：</span><br />
          1. REITs概况： <span>目前分钟线改为日内实时分钟线；</span><br />
          2. REITs估值部分：<span>增加了隐含Cap Rate 、PAFFO口径数据</span><br />
          3. REITs解禁份额：<span>使用了新的OCR及AI分析工具，目前准确率及完整度更高；</span><br />
        </div>
      </div>
      <div class="tips">
        <div class="checked" @click="checked = !checked">
          <div class="g" v-show="checked">√</div>
        </div>
        <div class="tips_t" @click="checked = !checked">不再显示</div>
      </div>
    </div>
  </div>
</template>

<script>
import { storageUpdate } from '@/utils/util';
export default {
  props: {
    show: Boolean,
    callback: Function,
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    close() {
      this.$emit('update:show', false);
      storageUpdate(this.checked);
      if (this.checked) {
        typeof this.callback === 'function' && this.callback();
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
@vh:1 /10.8vh;
.shadow {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 999;

  .close_b {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
  }
  .case {
    position: absolute;
    width: 80%;
    height: 80vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(42, 43, 47, 0.9);
    border-radius: 5vh;
    border: 2px solid white;
    color: white;
    font-size: 18 * @vh;
    .big_title {
      margin: auto;
      padding-top: 1vh;
      width: 95%;
      height: 5vh;
      border-bottom: 1px solid white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 22 * @vh;
      .close {
        font-size: 35 * @vh;
        line-height: 1;
        cursor: pointer;
      }
    }
    .time {
      margin: auto;
      width: 95%;
      height: 4vh;
      line-height: 4vh;
      font-size: 16 * @vh;
    }
    .content {
      margin: auto;
      width: 95%;
      height: 64vh;
      line-height: 2;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 10 * @vh;
        height: 10 * @vh;
        background-color: #dfd8d0;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #4de7e9;
      }
      span {
        color: #4de7e9;
      }
      .b_t {
        margin: auto;
        width: 100%;
      }
      .s_t {
        margin: auto;
        width: 93%;
      }
    }
    .tips {
      margin: auto;
      padding-top: 1vh;
      width: 90%;
      height: 3vh;
      display: flex;
      align-items: center;
      font-size: 16 * @vh;
      .checked {
        margin-right: 1vh;
        width: 2vh;
        height: 2vh;
        box-sizing: border-box;
        border: 1px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #4de7e9;
        font-size: 22 * @vh;
        cursor: pointer;
      }
      .tips_t {
        margin-right: 4vh;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
</style>
