<template>
  <div class="container" id="rootchart" :style="cssVars">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="概览" name="first">
        <summer ref="summer"></summer>
      </el-tab-pane>
      <el-tab-pane label="动态估值" name="second">
        <div class="card">
          <div class="card_top">
            <span>
              <CCascader
                ref="CCascader_ref_1"
                :default="category_cascader_valuationTable_val"
                :checkStrictly="true"
                class="mr20"
                :collapsetags="true"
                @CCascader_select_val="fnCCascader_select_val"
                :options="category_cascader_valuationTable"
                >选择类别</CCascader
              >

              <CSelect
                @CSelect_select_val="fnCSelect_select_val"
                :clearable="false"
                :multiple="true"
                :limit="8"
                :middle="true"
                class="mr20"
                :default="category_select_valuationTable_val"
                :options="category_options_valuationTable"
                >选择估值指标</CSelect
              >

              <span class="summary_style">
                汇总
                <el-checkbox
                  @change="fnGetValuationTable"
                  v-model="summaryChecked_DynamicValuation"></el-checkbox>
              </span>
            </span>

            <span>
              <div class="downloadBtns">
                <CButtonsDownload
                  :dButtonsDownloads="dButtonsDownloads1"
                  @fnButtondownload="fnButtonDL7_1" />

                <span style="display: none">
                  <download-excel
                    id="downloadExcel7_1"
                    :data="dValuationTable"
                    :fields="json_fields"
                    :header="title"
                    name="动态估值.xls">
                  </download-excel>
                </span>
              </div>
            </span>
          </div>
          <div class="card_bottom">
            <el-table
              :height="OFFSETHEIGHT - 255"
              class="table1"
              :header-cell-style="styleBindFun"
              :cell-style="fnCell"
              @cell-click="fnClick"
              :data="dValuationTable"
              style="width: 100%"
              header-row-class-name="valutionanalysis_yield_ttm">
              <el-table-column :width="90" prop="代码" label="代码"> </el-table-column>
              <el-table-column :width="eltableWidth2" prop="名称" label="名称"> </el-table-column>
              <el-table-column :width="80" prop="大类" label="大类"> </el-table-column>
              <el-table-column :width="90" prop="类型" label="类型"> </el-table-column>

              <el-table-column align="left" label="PB" sortable v-if="bPB" prop="PB_ttm">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width: scope.row.node_PB !== 'NaNpx' ? scope.row.node_PB : '',
                      }"
                      class="a"></div>
                    {{
                      Number(scope.row.PB_ttm) > 10
                        ? Number(scope.row.PB_ttm).toFixed(1) + 'x'
                        : (Number(scope.row.PB_ttm).toFixed(2) + 'x') | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="left" label="PNAV" sortable v-if="bPNAV" prop="PNAV_ttm">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width: scope.row.node_PNAV !== 'NaNpx' ? scope.row.node_PNAV : '',
                      }"
                      class="b"></div>
                    {{
                      Number(scope.row.PNAV_ttm) > 10
                        ? Number(scope.row.PNAV_ttm).toFixed(1) + 'x'
                        : (Number(scope.row.PNAV_ttm).toFixed(2) + 'x') | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="left" label="PFFO" sortable v-if="bPFFO" prop="PFFO_ttm">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width: scope.row.node_PFFO !== 'NaNpx' ? scope.row.node_PFFO : '',
                      }"
                      class="c"></div>
                    {{
                      Number(scope.row.PFFO_ttm) > 10
                        ? Number(scope.row.PFFO_ttm).toFixed(1) + 'x'
                        : (Number(scope.row.PFFO_ttm).toFixed(2) + 'x') | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="left" label="PCFO" sortable v-if="bPCFO" prop="PCFO_ttm">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width: scope.row.node_PCFO !== 'NaNpx' ? scope.row.node_PCFO : '',
                      }"
                      class="d"></div>
                    {{
                      Number(scope.row.PCFO_ttm) > 10
                        ? Number(scope.row.PCFO_ttm).toFixed(1) + 'x'
                        : (Number(scope.row.PCFO_ttm).toFixed(2) + 'x') | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="left"
                label="EV/EBITDA"
                sortable
                v-if="bEVEBITDA"
                prop="EV/EBITDA_ttm">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width: scope.row.node_EVEBITDA !== 'NaNpx' ? scope.row.node_EVEBITDA : '',
                      }"
                      class="e"></div>
                    {{
                      Number(scope.row['EV/EBITDA_ttm']) > 10
                        ? Number(scope.row['EV/EBITDA_ttm']).toFixed(1) + 'x'
                        : (Number(scope.row['EV/EBITDA_ttm']).toFixed(2) + 'x') | isZero
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="left"
                label="分红回报率"
                :render-header="renderHeader"
                v-if="bDIVYIELD"
                sortable
                prop="div_yield_ttm">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width: scope.row.node_DIVYIELD !== 'NaNpx' ? scope.row.node_DIVYIELD : '',
                      }"
                      class="e"></div>
                    {{ ((Number(scope.row['div_yield_ttm']) * 100).toFixed(2) + '%') | isZero }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="left"
                label="隐含Cap Rate"
                :render-header="renderHeader"
                v-if="bIMPLIEDCAPRATE"
                sortable
                prop="implied_cap_rate"
                width="170">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width:
                          scope.row.node_IMPLIEDCAPRATE !== 'NaNpx'
                            ? scope.row.node_IMPLIEDCAPRATE
                            : '',
                      }"
                      class="e"></div>
                    {{ ((Number(scope.row['implied_cap_rate']) * 100).toFixed(2) + '%') | isZero }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="left" label="PAFFO" v-if="bPAFFO" sortable prop="paffo_ttm">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width: scope.row.node_PAFFO !== 'NaNpx' ? scope.row.node_PAFFO : '',
                      }"
                      class="e"></div>
                    {{
                      Number(scope.row['paffo_ttm']) > 10
                        ? Number(scope.row['paffo_ttm']).toFixed(1) + 'x'
                        : (Number(scope.row['paffo_ttm']).toFixed(2) + 'x') | isZero
                    }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div ref="table7_1" class="card_bottom downloadTable">
            <el-table
              class="table1"
              :header-cell-style="styleBindFun"
              :cell-style="fnCell"
              @cell-click="fnClick"
              :data="dValuationTable"
              style="width: 100%"
              header-row-class-name="valutionanalysis_yield_ttm">
              <el-table-column :width="eltableWidth3" prop="代码" label="代码"> </el-table-column>
              <el-table-column :width="eltableWidth2" prop="名称" label="名称"> </el-table-column>
              <el-table-column :width="eltableWidth3" prop="大类" label="大类"> </el-table-column>
              <el-table-column :width="eltableWidth2" prop="类型" label="类型"> </el-table-column>

              <el-table-column align="left" label="PB" sortable v-if="bPB" prop="PB_ttm">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width: scope.row.node_PB !== 'NaNpx' ? scope.row.node_PB : '',
                      }"
                      class="a"></div>
                    {{
                      Number(scope.row.PB_ttm) > 10
                        ? Number(scope.row.PB_ttm).toFixed(1) + 'x'
                        : (Number(scope.row.PB_ttm).toFixed(2) + 'x') | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="left" label="PNAV" sortable v-if="bPNAV" prop="PNAV_ttm">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width: scope.row.node_PNAV !== 'NaNpx' ? scope.row.node_PNAV : '',
                      }"
                      class="b"></div>
                    {{
                      Number(scope.row.PNAV_ttm) > 10
                        ? Number(scope.row.PNAV_ttm).toFixed(1) + 'x'
                        : (Number(scope.row.PNAV_ttm).toFixed(2) + 'x') | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="left" label="PFFO" sortable v-if="bPFFO" prop="PFFO_ttm">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width: scope.row.node_PFFO !== 'NaNpx' ? scope.row.node_PFFO : '',
                      }"
                      class="c"></div>
                    {{
                      Number(scope.row.PFFO_ttm) > 10
                        ? Number(scope.row.PFFO_ttm).toFixed(1) + 'x'
                        : (Number(scope.row.PFFO_ttm).toFixed(2) + 'x') | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="left" label="PCFO" sortable v-if="bPCFO" prop="PCFO_ttm">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width: scope.row.node_PCFO !== 'NaNpx' ? scope.row.node_PCFO : '',
                      }"
                      class="d"></div>
                    {{
                      Number(scope.row.PCFO_ttm) > 10
                        ? Number(scope.row.PCFO_ttm).toFixed(1) + 'x'
                        : (Number(scope.row.PCFO_ttm).toFixed(2) + 'x') | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="left"
                label="EV/EBITDA"
                sortable
                v-if="bEVEBITDA"
                prop="EV/EBITDA_ttm">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width: scope.row.node_EVEBITDA !== 'NaNpx' ? scope.row.node_EVEBITDA : '',
                      }"
                      class="e"></div>
                    {{
                      Number(scope.row['EV/EBITDA_ttm']) > 10
                        ? Number(scope.row['EV/EBITDA_ttm']).toFixed(1) + 'x'
                        : (Number(scope.row['EV/EBITDA_ttm']).toFixed(2) + 'x') | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="left"
                label="分红回报率"
                :render-header="renderHeader"
                v-if="bDIVYIELD"
                sortable
                prop="div_yield_ttm">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width: scope.row.node_DIVYIELD !== 'NaNpx' ? scope.row.node_DIVYIELD : '',
                      }"
                      class="e"></div>
                    {{ ((Number(scope.row['div_yield_ttm']) * 100).toFixed(2) + '%') | isZero }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="left"
                label="隐含Cap Rate"
                :render-header="renderHeader"
                v-if="bIMPLIEDCAPRATE"
                sortable
                prop="implied_cap_rate">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width:
                          scope.row.node_IMPLIEDCAPRATE !== 'NaNpx'
                            ? scope.row.node_IMPLIEDCAPRATE
                            : '',
                      }"
                      class="e"></div>
                    {{ ((Number(scope.row['implied_cap_rate']) * 100).toFixed(2) + '%') | isZero }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="left" label="PAFFO" v-if="bPAFFO" sortable prop="paffo_ttm">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width: scope.row.node_PAFFO !== 'NaNpx' ? scope.row.node_PAFFO : '',
                      }"
                      class="e"></div>
                    {{
                      Number(scope.row['paffo_ttm']) > 10
                        ? Number(scope.row['paffo_ttm']).toFixed(1) + 'x'
                        : (Number(scope.row['paffo_ttm']).toFixed(2) + 'x') | isZero
                    }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="历史估值" name="third">
        <div class="card">
          <div class="card_tit HistoricalValuation">
            <span>历史数据</span>

            <div class="downloadBtns">
              <CButtonsDownload
                :dButtonsDownloads="dButtonsDownloads2"
                @fnButtondownload="fnButtonDL7_2" />

              <span style="display: none">
                <download-excel
                  id="downloadExcel7_2"
                  :data="json_fields_data1_data"
                  :fields="json_fields_data1"
                  header="历史数据"
                  name="历史数据.xls">
                </download-excel>
              </span>

              <span style="display: none">
                <download-excel
                  id="downloadExcel7_3"
                  :data="json_fields_pic1_lines_data"
                  :fields="json_fields_pic1_lines"
                  header="历史数据_参考线"
                  name="历史数据_参考线.xls">
                </download-excel>
              </span>

              <span style="display: none">
                <download-excel
                  id="downloadExcel7_4"
                  :data="json_fields_ipo_date_data"
                  :fields="json_fields_ipo_date"
                  header="历史数据_IPO数据"
                  name="历史数据_IPO数据.xls">
                </download-excel>
              </span>
            </div>
          </div>

          <div class="card_history_top">
            <CCascader
              class="mr20"
              @CCascader_select_val="fnCCascader_select_val_two"
              :default="r_type_chart4"
              :checkStrictly="true"
              :options="category_cascader_valuationTable"
              >选择REITs</CCascader
            >

            <CSelect
              class="mr20"
              :default="factor_chart4"
              :clearable="false"
              :auto="true"
              @CSelect_select_val="fnCSelect_select_val_two"
              :options="category_options_valuationTable"
              >选择估值指标</CSelect
            >

            <span>
              <CCategoryButton
                :default="show_mode_chart4"
                @CCategoryButton_select_val="CCategoryButton_select_val">
                参考线
              </CCategoryButton>
            </span>
          </div>

          <div class="card_history_top">
            <span class="btnArea btnArea_center">
              <CTimeButtons
                :default="start_date_chart4"
                @CTimeButtons_select_val="fnCTimeButtons_select_val" />
            </span>
          </div>

          <div class="main historymain HistoricalValuationMain">
            <span @click="fnOpenDialog3" class="iconfont icon-fangda icon-fangda2"></span>
            <div
              v-loading="loading_chart4"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="canvasArea"
              id="chartColumn4"
              ref="chartColumn4"
              style="width: 100%; height: 500px"></div>
          </div>

          <div class="other_chart1_style">
            <div>
              <span class="other_chart1">
                <CotherButtons
                  :default="other_chart1"
                  @CotherButtons_select_val="CotherButtons_select_val" />
              </span>
            </div>
            <div class=""></div>
          </div>

          <div class="main scatterArea">
            <div class="canvasArea" ref="chartColumn5" style="width: 100%; height: 390px"></div>

            <div
              v-loading="loading_chart4"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="canvasArea"
              ref="chartColumn6"
              style="width: 100%; height: 390px"></div>
          </div>

          <div class="HistoricalValuation_info">
            <div>说明</div>
            <div>
              1.市盈率=成分股当日总市值总计/成分股净利润(TTM)总计，当成分股净利润(TTM)为负时，市盈率为0
            </div>
            <div>2.加权指标是指按照指数加权方式计算的指标，部分指数没有权重数据则没有相关指标</div>
            <div>
              3.分位点为将某一个数值在其所在的数组中的分布点，对于估值分布来说就是“当前估值在历史中出于什么样的位置”
            </div>
            <div>4.机会值、中位数以及危险值分别对应了20%、50%、80%三个分位点</div>
            <div>
              5.历史估值对应未来1年收益率的选取了过去10年内120个估值点以及每个估值点对应的未来1年的指数涨跌幅。用以描述估值高低与未来涨跌幅是否存在相关关系`
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="估值Bands" name="fourth">
        <div class="card" ref="chartArea7">
          <div class="card_tit">
            估值Bands

            <div class="downloadBtns">
              <CButtonsDownload
                :dButtonsDownloads="dButtonsDownloads2"
                @fnButtondownload="fnButtonDL7_3" />

              <span style="display: none">
                <download-excel
                  id="downloadExcel7_61"
                  :data="DATA_chart7ba"
                  :fields="json_fields_column7"
                  header="估值Bands"
                  name="估值Bands.xls">
                </download-excel>
              </span>
            </div>
          </div>
          <div class="card_history_top">
            <CCascader
              class="mr20"
              :checkStrictly="true"
              @CCascader_select_val="fnCCascader_select_val_three"
              :default="r_type_chart7"
              :options="category_cascader_valuationTable"
              >选择大类</CCascader
            >

            <CSelect
              @CSelect_select_val="fnCSelect_select_val_three"
              :default="valuation_chart7"
              :clearable="false"
              :auto="true"
              :options="category_options_valuationTable"
              >选择估值指标</CSelect
            >

            <CTimeButtons
              class="ml20"
              :default="start_date_LISHISHUJU"
              @CTimeButtons_select_val="fnCTimeButtons_select_val_LISHISHUJU" />
          </div>

          <div class="main historymain">
            <span @click="fnOpenDialog2" class="iconfont icon-fangda icon-fangda2"></span>
            <div v-show="DATA_chart7.length == 0" style="margin: auto">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div
              v-show="DATA_chart7.length > 0"
              v-loading="loading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="canvasArea"
              id="chartColumn7"
              ref="chartColumn7"
              style="width: 100%; height: 530px"></div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="IRR/NPV走势" name="fifth">
        <irr ref="irr"></irr>
      </el-tab-pane>

      <CDialog ref="CDialog" :DIALOGHEIGHT="height_dialog" :dialogVisible="dialogVisible2">
        <el-dialog
          title="葱花投研"
          :visible.sync="dialogVisible2"
          :width="width_dialog"
          :top="top_dialog">
          <div>
            <el-dropdown size="mini" trigger="click" type="primary" @command="fnDropdown">
              <span class="el-dropdown-link">
                {{ popUpWindowDatas[popUpWindowIndex]
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="idx" v-for="(node, idx) in popUpWindowDatas" :key="idx">
                  {{ node }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div :style="{ height: height_chart }">
            <div v-show="DATA_chart7.length == 0">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div
              v-show="DATA_chart7.length > 0"
              v-loading="loading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="canvasArea"
              id="chartColumn7"
              ref="chartColumn7"
              style="width: 100%; height: 100%"></div>
          </div>
        </el-dialog>
      </CDialog>

      <CDialog ref="CDialog" :DIALOGHEIGHT="height_dialog" :dialogVisible="dialogVisible3">
        <el-dialog
          title="葱花投研"
          :visible.sync="dialogVisible3"
          :width="width_dialog"
          :top="top_dialog">
          <div>
            <el-dropdown size="mini" trigger="click" type="primary" @command="fnDropdown3">
              <span class="el-dropdown-link">
                {{ popUpWindowDatas[popUpWindowIndex]
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="idx" v-for="(node, idx) in popUpWindowDatas" :key="idx">
                  {{ node }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div :style="{ height: height_chart }">
            <div
              v-loading="loading_chart4"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="canvasArea"
              id="chartColumn4"
              ref="chartColumn4"
              style="width: 100%; height: 100%"></div>
          </div>
        </el-dialog>
      </CDialog>
    </el-tabs>
  </div>
</template>

<script>
import CSelect from '@/components/Basic/CSelect';
import CCategoryButton from '@/components/Basic/CCategoryButton';
import CCascader from '@/components/Basic/CCascader';
import CTimeButtons from '@/components/Basic/CTimeButtons';
import CotherButtons from '@/components/Basic/CotherButtons';
import CButtonsSeparate from '@/components/Basic/CButtons_separate';
import CButtonsDownload from '@/components/Basic/CButtons_download';
import CDialog from '@/components/Basic/CDialog';

import html2canvas from 'html2canvas';
import ecStat from 'echarts-stat';
import summer from './summer.vue';
import irr from './irr.vue';

import {
  fnGetLevelName,
  fnReturnTableStyle,
  fnGetMuchDecimals,
  fnDownloadBgc,
  hexToRgba,
  fnComputeTime,
  exportToExcel,
} from '@/utils/util';

export default {
  computed: {},
  created() {
    // this.fnGetL1();
    let name = localStorage.getItem('activeName');
    this.handleClick({ name: name });
  },
  mounted() {
    this.fnGetWidth();
    window.addEventListener('resize', this.funResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.funResize);

    this.chartColumn4 && this.chartColumn4.dispose();
    this.chartColumn5 && this.chartColumn5.dispose();
    this.chartColumn6 && this.chartColumn6.dispose();
    this.chartColumn7 && this.chartColumn7.dispose();
    this.chartColumn8 && this.chartColumn8.dispose();
    this.chartColumn9 && this.chartColumn9.dispose();
  },
  data() {
    return {
      OFFSETHEIGHT: 0,
      TABPANEHEIGHT: 0,

      loading: false,
      loading_chart4: false,

      isVip: false,

      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,

      width_dialog: '80%',
      height_dialog: '73vh',
      height_chart: '71vh',
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: '5vh',

      ROOTFONTFIZE: 0,
      eltableWidth: 80,
      eltableWidth2: 150,
      eltableWidth3: 100,

      value: '',

      chartColumn4: null,
      chartColumn5: null,
      chart5_arr2: null,
      chartColumn6: null,
      initChart8_1_arr1: null,
      initChart8_1_lastday: null,

      chartColumn7: null,
      chartColumn8: null,
      chartColumn9: null,
      chartColumn9_dialog: null,

      dLastAssetValue: [],
      dRpDateAssetValue: [],

      activeName: 'first',

      category_options: [],

      category_options_valuationTable: this.$store.state.valuationTable4,

      category_cascader_valuationTable: JSON.parse(localStorage.getItem('CATEGORYDATA')),

      dBtn1: ['3M', '6M', '1Y', 'YTD', 'MAX'],
      activeBtn1: '3M',

      dValuationBandByType: [],
      dValuationBandByType_X: [],
      dValuationBandByType_97x: [],
      dValuationBandByType_0x: [],
      dValuationBandByType_11x: [],
      dValuationBandByType_19x: [],
      dValuationBandByType_23x: [],
      dValuationBandByType_current: [],

      // 表格
      dValuationTable: [],

      summaryChecked_DynamicValuation: true,

      ReLine: ['分位数', '标准差'],
      activeBtn1: '分位数',

      dValuationAnalysisSingle: [],
      dValuationAnalysisSingle_X: [],
      dValuationAnalysisSingle_YL: [],
      dValuationAnalysisSingle_YR: [],
      dValuationAnalysisSingle_IPO: [],

      dValuationAnalysisSingle_pic1_lines: null,
      dhistogramData_X: [],
      dhistogramData_Y: [],

      last_red_line: '',
      histogram_last_val: '',

      // 表格1
      category_cascader_valuationTable_val: '公募REITs',
      category_select_valuationTable_val: [
        'PFFO',
        'PCFO',
        'PNAV',
        'PB',
        'EV/EBITDA',
        'div_yield_ttm',
        'implied_cap_rate',
        'PAFFO',
      ],
      bPB: true,
      bPNAV: true,
      bPFFO: true,
      bPCFO: true,
      bEVEBITDA: true,
      bPDIV: true,
      bDIVYIELD: true,
      bIMPLIEDCAPRATE: true,
      bPAFFO: true,

      // chart7
      r_type_chart7: ['公募REITs'],
      valuation_chart7: 'PB',
      start_date_LISHISHUJU: 'MAX',
      DATA_chart7: [],
      DATA_chart7ba: [],

      // chart4
      factor_chart4: 'PB',
      start_date_chart4: 'YTD',
      show_mode_chart4: 'std',
      r_type_chart4: ['公募REITs'],
      other_chart1: '1M',

      // chart5
      start_date_chart5: '3M',
      dButtons_separrate_chart5: ['平滑', '阶梯'],
      dButtons_separrate_current_chart5: '平滑',

      // 表格下载
      title: '动态估值',
      json_fields: {},

      json_fields_data1: {},
      json_fields_data1_data: [],

      json_fields_pic1_lines: {},
      json_fields_pic1_lines_data: [],

      json_fields_ipo_date: {},
      json_fields_ipo_date_data: [],

      dButtonsDownloads1: [
        {
          id: '241047',
          name: '下载图片',
        },
        {
          id: '241047',
          name: '下载表格',
        },
      ],

      dButtonsDownloads2: [
        {
          id: '241053',
          name: '下载图片',
        },
        {
          id: '241049',
          name: '下载表格',
        },
      ],

      json_fields_column7: {},

      // dialogVisible: false,

      // 表格下载 end

      // L1
      L1MAX: 0,
      L1MAX_percen: 0,
      // L2 CQ
      CQMAX: 0,
      CQMAX_percen: 0,

      // L2 JYQ
      JYQMAX: 0,
      JYQMAX_percen: 0,

      sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,

      GMREITs: '',
      CQLREITs: '',
      JYQLREITs: '',
      WLCC: '',
      CYY: '',
      BZXZF: '',
      XF: '',
      SZST: '',
      NY: '',
      SFGL: '',
    };
  },
  methods: {
    funResize() {
      this.$nextTick(() => {
        if (this.chartColumn4) {
          this.chartColumn4.resize();
        }
        if (this.chartColumn5) {
          this.$nextTick(() => {
            this.initChart5();
          });
        }
        if (this.chartColumn6) {
          this.chartColumn6.resize();
        }
        if (this.chartColumn3) {
          this.chartColumn3.resize();
        }
        if (this.chartColumn7) {
          this.chartColumn7.resize();
        }
        if (this.chartColumn8) {
          this.chartColumn8.resize();
        }
        if (this.chartColumn9) {
          this.chartColumn9.resize();
        }
      });
    },

    fnGetTheme() {
      console.log('fnGetTheme index');
      this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
    },

    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1440 ? Number(ROOTFONTFIZE) - 200 : ROOTFONTFIZE;

      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      window.sessionStorage.setItem('ROOTFONTFIZE', this.ROOTFONTFIZE);

      let OFFSETHEIGHT = element.offsetHeight;
      this.TABPANEHEIGHT = OFFSETHEIGHT - 135;
      this.OFFSETHEIGHT = OFFSETHEIGHT;

      return;
    },

    // 下载 start
    fnButtonDL7_1(val) {
      if (val == '下载图片') {
        this.FnDownload7_1();
      } else {
        document.getElementById('downloadExcel7_1').click();
      }
    },

    FnDownload7_1() {
      let h = this.$refs.table7_1.scrollHeight;
      let w = this.$refs.table7_1.scrollWidth;
      let bgColor = fnDownloadBgc(this.sCurrentTheme);
      console.log('FnDownload7_1', bgColor);
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.table7_1, {
        async: true,
        height: h + 20,
        windowHeight: h + 20,
        width: w,
        backgroundColor: bgColor,
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '动态估值'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    fnButtonDL7_2(val) {
      if (val == '下载图片') {
        this.FnDownload7_2();
      } else {
        document.getElementById('downloadExcel7_2').click();
        document.getElementById('downloadExcel7_3').click();
        document.getElementById('downloadExcel7_4').click();
      }
    },

    fnButtonDL7_3(val) {
      if (val == '下载图片') {
        this.FnDownload7_3();
      } else {
        // exportToExcel('#chartColumn7','第二章')
        document.getElementById('downloadExcel7_61').click();
      }
    },

    // 下载 end

    // 放大 start
    fnOpenDialog2() {
      this.dialogVisible2 = true;
      this.$nextTick(() => {
        this.initChart7(true);
      });
    },

    fnDropdown(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart7(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart7(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart7(true);
        });
      }
    },

    fnDropdown3(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      }
    },

    // 放大 end

    renderHeader(h, { column }) {
      // return h('span', {}, [
      //   h('span', {}, column.label.split('/')[0]),
      //   h('br'),
      //   h('span', {}, column.label.split('/')[1]),
      // ]);
      var str = '';
      if (column.label == '分红回报率') {
        str = 'TTM口径';
      }
      if (column.label == '隐含Cap Rate') {
        str = '推算NOI_ttm与市值ev(市值+负债余额)计算得到';
      }
      return h('span', [
        h('span', column.label),
        h(
          'el-tooltip',
          {
            props: {
              effect: 'dark',
              content: str,
              placement: 'top',
            },
          },
          [
            h('i', {
              class: 'el-icon-question',
              style: 'color:#409eff;margin-left:5px;',
            }),
          ]
        ),
      ]);
    },
    FnDownload7_2() {
      let h = this.$refs.chartColumn4.scrollHeight;
      let w = this.$refs.chartColumn4.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn4, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '历史数据'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    FnDownload7_3() {
      let h = this.$refs.chartColumn7.scrollHeight;
      let w = this.$refs.chartColumn7.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn7, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '历史数据'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });

      this.FnDownload7_3_2();
    },

    fnOpenDialog3() {
      this.dialogVisible3 = true;
      this.$nextTick(() => {
        this.initChart4(true);
      });
    },

    //  表头方法
    styleBindFun() {
      let val = this.$store.state.theme == 'dark' ? true : false;
      return fnReturnTableStyle(val);
    },

    // 表格1
    fnCCascader_select_val(val) {
      // // 1.
      // if (
      //   this.category_cascader_valuationTable_val.includes("公募REITs") &&
      //   val.length > 1
      // ) {
      //   val.splice(val.length - 1, 1);
      // }

      // // 2.
      // if (
      //   val.includes("公募REITs") &&
      //   this.category_cascader_valuationTable_val.length > 1
      // ) {
      //   val = [];
      //   val.push(["公募REITs"]);
      // }

      // this.$refs.CCascader_ref_1.value = val;
      this.category_cascader_valuationTable_val = val;
      this.summaryChecked_DynamicValuation = false;
      this.fnGetValuationTable();
    },

    fnCSelect_select_val(val) {
      this.category_select_valuationTable_val = val;
      this.summaryChecked_DynamicValuation = false;
      this.fnGetValuationTable();
    },

    async fnGetValuationTable() {
      let r_type = this.category_cascader_valuationTable_val;
      // let str0 = r_type.join("&r_type=");

      let summarize = this.summaryChecked_DynamicValuation ? 1 : 0;

      let factor_list = this.category_select_valuationTable_val;
      let str = factor_list.join('&factor_list=');

      const { data } = await this.$https.get(
        '/api/valuation_table?r_type=' + r_type + '&summarize=' + summarize + '&factor_list=' + str
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      let orderData = JSON.parse(localStorage.getItem('ORDER'));
      let TMP = [];
      orderData.forEach((node) => {
        let obj = data.data.filter((item) => item['类型'] == node);
        if (obj.length > 0) {
          obj.forEach((item) => {
            TMP.push(item);
          });
        }
      });

      let obj2 = data.data.filter((item) => item['类型'] == '');
      if (obj2.length > 0) {
        obj2.forEach((item) => {
          TMP.push(item);
        });
      }
      this.dValuationTable = TMP;

      this.dValuationTable = data.data;

      if (summarize == 0) {
        this.dValuationTable.map((node) => {
          node['代码'] = node.reit_code;
          node['名称'] = node.REITs_name;
          node['大类'] = node.REITs_type_L1;
          node['类型'] = node.REITs_type_L2;

          delete node.reit_code;
          delete node.REITs_name;
          delete node.REITs_type_L1;
          delete node.REITs_type_L2;
        });
      }

      // 下载 start
      let json_fields = {
        代码: '代码',
        名称: '名称',
        大类: '大类',
        类型: '类型',
      };
      let arr = Object.keys(this.dValuationTable[0]);
      // arr.splice(0, 4);
      arr = arr.filter((node) => {
        return node !== '代码' || node !== '名称' || node !== '大类' || node !== '类型';
      });
      arr.forEach((node) => {
        json_fields[node] = node;
      });

      this.json_fields = json_fields;

      // 下载 end

      if (r_type !== '公募REITs') {
        let arr1 = this.dValuationTable.filter((node) => {
          return node['代码'] == '一类汇总' || node['代码'] == '总体汇总';
        });
        // 先删除一类汇总
        arr1.forEach((node) => {
          this.dValuationTable.splice(this.dValuationTable.indexOf(node), 1);
        });

        arr1.forEach((node) => {
          this.dValuationTable.push(node);
        });
      } else {
        // 1. 找出一类，总体
        let arr1 = this.dValuationTable.filter((node) => {
          return node['代码'] == '一类汇总' || node['代码'] == '总体汇总';
        });

        arr1.forEach((node) => {
          this.dValuationTable.splice(this.dValuationTable.indexOf(node), 1);
        });

        // 2. 插入到数组合适位置
        let idx = this.dValuationTable.findIndex((node) => {
          return node['大类'] == '经营权类';
        });

        let idx2 = null;
        let idx3 = null;

        arr1.forEach((node, index) => {
          if (node['大类'] == '产权类') {
            this.dValuationTable.splice(idx, 0, node);
          }

          if (node['大类'] == '经营权类') {
            idx2 = index;
          }

          if (node['大类'] == '总体汇总') {
            idx3 = index;
          }
        });

        if (arr1[idx2] !== undefined && arr1[idx3]) {
          this.dValuationTable.push(arr1[idx2]);
          this.dValuationTable.push(arr1[idx3]);
        }
      }

      // END--------------------------------------------------------------------------------------------

      let CATEGORY = this.dValuationTable.map((node) => {
        return node['类型'];
      });

      CATEGORY = [...new Set(CATEGORY)];

      // 排序
      let ARRTMP = [];
      CATEGORY.forEach((node) => {
        this.dValuationTable.forEach((e) => {
          if (e['类型'] == node) {
            ARRTMP.push(e);
          }
        });
      });
      // this.dValuationTable = ARRTMP;

      let DATA = [];
      CATEGORY.forEach((e) => {
        let obj = {
          name: e,
          max_PB_ttm: 0,
          max_PNAV_ttm: 0,
          max_PFFO_ttm: 0,
          max_PCFO_ttm: 0,
          max_EVEBITDA_ttm: 0,
          // max_pdiv_ttm: 0,
          max_div_yield_ttm: 0,
          max_implied_cap_rate: 0,
          max_paffo_ttm: 0,
        };

        // let data = this.dValuationTable.filter((item) => {
        //   if (item["类型"] == e && item["代码"] !== "二类汇总") {
        //     return item;
        //   }
        // });

        let data = this.dValuationTable;

        let data2 = data.map((node) => {
          return node['PB_ttm'];
        });

        let data3 = data.map((node) => {
          return node['PNAV_ttm'];
        });

        let data4 = data.map((node) => {
          return node['PFFO_ttm'];
        });

        let data5 = data.map((node) => {
          return node['PCFO_ttm'];
        });

        let data6 = data.map((node) => {
          return node['EV/EBITDA_ttm'];
        });

        // let data7 = data.map((node) => {
        //   return node["pdiv_ttm"];
        // });

        let data8 = data.map((node) => {
          return node['div_yield_ttm'];
        });

        let data9 = data.map((node) => {
          return node['implied_cap_rate'];
        });

        let data10 = data.map((node) => {
          return node['paffo_ttm'];
        });

        if (data2[0] == undefined) {
          this.bPB = false;
        } else {
          this.bPB = true;
        }

        if (data3[0] == undefined) {
          this.bPNAV = false;
        } else {
          this.bPNAV = true;
        }

        if (data4[0] == undefined) {
          this.bPFFO = false;
        } else {
          this.bPFFO = true;
        }

        if (data5[0] == undefined) {
          this.bPCFO = false;
        } else {
          this.bPCFO = true;
        }

        if (data6[0] == undefined) {
          this.bEVEBITDA = false;
        } else {
          this.bEVEBITDA = true;
        }

        // if (data7[0] == undefined) {
        //   this.bPDIV = false;
        // } else {
        //   this.bPDIV = true;
        // }

        if (data8[0] == undefined) {
          this.bDIVYIELD = false;
        } else {
          this.bDIVYIELD = true;
        }

        if (data9[0] == undefined) {
          this.bIMPLIEDCAPRATE = false;
        } else {
          this.bIMPLIEDCAPRATE = true;
        }

        if (data10[0] == undefined) {
          this.bPAFFO = false;
        } else {
          this.bPAFFO = true;
        }

        obj.max_PB_ttm = Math.max(...data2);
        obj.max_PNAV_ttm = Math.max(...data3);
        obj.max_PFFO_ttm = Math.max(...data4);
        obj.max_PCFO_ttm = Math.max(...data5);
        obj.max_EVEBITDA_ttm = Math.max(...data6);
        // obj.max_pdiv_ttm = Math.max(...data7);
        obj.max_div_yield_ttm = Math.max(...data8);
        obj.max_implied_cap_rate = Math.max(...data9);
        obj.max_paffo_ttm = Math.max(...data10);

        DATA.push(obj);
      });

      DATA.forEach((node) => {
        this.dValuationTable.map((e) => {
          if (node.name == e['类型']) {
            let node_PB = '';
            let val = Number(e['PB_ttm']) / node.max_PB_ttm;
            val = (Number(val) * 100).toFixed(0);
            node_PB = val + 'px';
            e.node_PB = node_PB;

            let node_PNAV = '';
            let val2 = Number(e['PNAV_ttm']) / Number(node.max_PNAV_ttm);
            val2 = (Number(val2) * 100).toFixed(0);
            node_PNAV = val2 + 'px';
            e.node_PNAV = node_PNAV;

            let node_PFFO = '';
            let val3 = Number(e['PFFO_ttm']) / node.max_PFFO_ttm;
            val3 = (Number(val3) * 100).toFixed(0);
            node_PFFO = val3 + 'px';
            e.node_PFFO = node_PFFO;

            let node_PCFO = '';
            let val4 = Number(e['PCFO_ttm']) / node.max_PCFO_ttm;
            val4 = (Number(val4) * 100).toFixed(0);
            node_PCFO = val4 + 'px';
            e.node_PCFO = node_PCFO;

            let node_EVEBITDA = '';
            let val5 = Number(e['EV/EBITDA_ttm']) / node.max_EVEBITDA_ttm;
            val5 = (Number(val5) * 100).toFixed(0);
            node_EVEBITDA = val5 + 'px';
            e.node_EVEBITDA = node_EVEBITDA;

            let node_DIVYIELD = '';
            let val7 = Number(e['div_yield_ttm']) / node.max_div_yield_ttm;
            val7 = (Number(val7) * 100).toFixed(0);
            node_DIVYIELD = val7 + 'px';
            e.node_DIVYIELD = node_DIVYIELD;

            let node_IMPLIEDCAPRATE = '';
            let val8 = Number(e['implied_cap_rate']) / node.max_implied_cap_rate;
            val8 = (Number(val8) * 100).toFixed(0);
            node_IMPLIEDCAPRATE = val8 + 'px';
            e.node_IMPLIEDCAPRATE = node_IMPLIEDCAPRATE;

            let node_PAFFO = '';
            let val9 = Number(e['paffo_ttm']) / node.max_paffo_ttm;
            val9 = (Number(val9) * 100).toFixed(0);
            node_PAFFO = val9 + 'px';
            e.node_PAFFO = node_PAFFO;
          }
        });
      });

      if (summarize == 0) {
        let LEIXING = this.dValuationTable.map((node) => {
          return node['类型'];
        });

        LEIXING = [...new Set(LEIXING)];

        let tmpARR = [];
        LEIXING.forEach((node) => {
          let arr = this.dValuationTable.filter((item) => {
            return item['类型'] == node;
          });

          arr.forEach((e) => {
            tmpARR.push(e);
          });
        });

        this.dValuationTable = tmpARR;
        console.log('数据', this.dValuationTable);
      }
    },

    // 2. cell
    fnCell({ row, column, rowIndex, columnIndex }) {
      if (row !== undefined && this.$store.state.theme == 'dark') {
        if (row.代码 == '二类汇总') {
          return 'background: #343742;color: rgba(218, 218, 218, 1);font-weight:700;letter-spacing: 0.21px;font-size: 13px;border-bottom: none; ';
        }
        if (row.代码 == '一类汇总' || row.代码 == '总体汇总') {
          return 'background: #4c4f5e;color: rgba(218, 218, 218, 1);font-weight:700;letter-spacing: 0.21px;font-size: 13px;border-bottom: none; ';
        }
      } else {
        if (row.代码 == '二类汇总') {
          return 'background: #fbfbfb;color: #707070;font-weight:700;letter-spacing: 0.21px;font-size: 13px;border-bottom: none; ';
          // return "background: #fbfbfb;color: #707070;font-weight:700;letter-spacing: 0.21px;font-size: 16px;border-bottom: none; ";
        }
        if (row.代码 == '一类汇总' || row.代码 == '总体汇总') {
          return 'background: #f5f5f5;color: #707070;font-weight:700;letter-spacing: 0.21px;font-size: 13px;border-bottom: none; ';
        }
      }
    },

    fnClick(row, column, cell) {
      let arr = [row['名称'], row['代码']];
      this.$eventBus.$emit('skipPage', arr);
    },

    fnChangeBtn1(val) {},

    // chart4
    fnCSelect_select_val_two(val) {
      this.factor_chart4 = val;
      this.fnGetValuationAnalysisSingle();
      this.fnGetValuationAnalysisSingle2();
    },
    fnCCascader_select_val_two(val) {
      this.r_type_chart4 = val;

      this.fnGetValuationAnalysisSingle();
      this.fnGetValuationAnalysisSingle2();
    },

    fnCTimeButtons_select_val(val) {
      this.start_date_chart4 = val;
      this.fnGetValuationAnalysisSingle();
      this.fnGetValuationAnalysisSingle2();
    },

    CCategoryButton_select_val(val) {
      if (val == '分位数') {
        val = 'percentile';
      } else {
        val = 'std';
      }
      this.show_mode_chart4 = val;

      this.fnGetValuationAnalysisSingle();
    },
    async fnGetValuationAnalysisSingle() {
      this.loading_chart4 = true;
      let r_type = this.r_type_chart4;
      let factor = this.factor_chart4;
      let start_date = this.start_date_chart4;
      let show_mode = this.show_mode_chart4;
      let n = '1M';

      const { data } = await this.$https.get(
        '/api/valuation_analysis_single?r_type=' +
          r_type +
          '&factor=' +
          factor +
          '&start_date=' +
          start_date +
          '&show_mode=' +
          show_mode +
          '&n=' +
          n
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 下载 start
      this.json_fields_data1 = {};
      this.json_fields_pic1_lines = {};
      this.json_fields_ipo_date = {};

      this.json_fields_data1_data = [];
      this.json_fields_pic1_lines_data = [];
      this.json_fields_ipo_date_data = [];

      // 1.data1
      this.json_fields_data1_data = data.data.data1;
      let factor123 = this.factor_chart4 + '（左轴）';
      this.json_fields_data1 = {
        日期: 'tr_date',
      };

      this.json_fields_data1[factor123] = 'factor_data-left';
      this.json_fields_data1['指数点位（右轴）'] = 'close_price-right';

      // 2. pic1_lines
      this.json_fields_pic1_lines_data.push(data.data.pic1_lines.line);
      // 分位线，标准差文案
      let text1 = this.show_mode_chart4 == 'std' ? '标准差（+1）' : '80分位数';
      let text2 = this.show_mode_chart4 == 'std' ? '标准差（-1）' : '20分位数';
      this.json_fields_pic1_lines[text1] = 'upper';
      this.json_fields_pic1_lines['平均值'] = 'upper';
      this.json_fields_pic1_lines[text2] = 'lower';

      // 3. ipo
      this.json_fields_ipo_date_data = data.data.ipo_date;
      this.json_fields_ipo_date = {
        日期: 'IPO_date',
        股票代码: 'reit_code',
        股票名称: 'REITs_name',
      };

      // 时间筛选
      let levelName = window.sessionStorage.getItem('levelName');
      if (levelName == '普通客户') {
        let result = fnComputeTime('3M');
        this.json_fields_data1_data = this.json_fields_data1_data.filter((node) => {
          return node['tr_date'] >= result;
        });

        this.json_fields_ipo_date_data = this.json_fields_ipo_date_data.filter((node) => {
          return node['IPO_date'] >= result;
        });
      } else {
        let result = fnComputeTime('6M');
        this.json_fields_data1_data = this.json_fields_data1_data.filter((node) => {
          return node['tr_date'] >= result;
        });

        this.json_fields_ipo_date_data = this.json_fields_ipo_date_data.filter((node) => {
          return node['IPO_date'] >= result;
        });
      }

      // 下载 end

      // 第一个图
      this.dValuationAnalysisSingle = data.data.data1;
      this.dValuationAnalysisSingle_X = [];
      this.dValuationAnalysisSingle_YL = [];
      this.dValuationAnalysisSingle_YR = [];
      // IPO
      this.dValuationAnalysisSingle_IPO = data.data.ipo_date;

      this.dValuationAnalysisSingle_pic1_lines = data.data.pic1_lines;

      for (let index = 0; index < this.dValuationAnalysisSingle.length; index++) {
        this.dValuationAnalysisSingle_X.push(this.dValuationAnalysisSingle[index].tr_date);
        this.dValuationAnalysisSingle_YL.push(
          this.dValuationAnalysisSingle[index]['factor_data-left']
        );
        this.dValuationAnalysisSingle_YR.push(
          this.dValuationAnalysisSingle[index]['close_price-right']
        );
      }

      //  直方图
      // 1. 拿到数据

      // 1.0 拿到最后一天的X轴数据
      let obj = this.dValuationAnalysisSingle[this.dValuationAnalysisSingle.length - 1];
      let lastday = Number(obj['factor_data-left']).toFixed(2);

      let dhistogramData = this.dValuationAnalysisSingle_YL;
      dhistogramData = dhistogramData.map((node) => {
        return Number(node).toFixed(2);
      });

      const result = dhistogramData.reduce((acc, value) => {
        const key = value.toString(); // 将值转换为字符串，作为键值
        if (!acc[key]) {
          acc[key] = []; // 如果键值不存在，则创建一个新数组作为值
        }
        acc[key].push(value); // 将当前值添加到对应的键值数组中
        return acc;
      }, {});

      let arr1 = Object.values(result);
      //  应该是不用的 - 0112
      let arr2 = arr1.map((node) => {
        let val = (Number(node.length) / Number(dhistogramData.length)) * 100;
        val = val.toFixed(1);
        if (String(val).length == 3) {
          return val + '0';
        } else {
          return val;
        }
      });

      arr1 = arr1.flat();

      let arr3 = Object.keys(result);

      this.dhistogramData_X = arr3;
      this.dhistogramData_Y = arr2;

      // 直方图markline
      let histogram_last = this.dValuationAnalysisSingle[this.dValuationAnalysisSingle.length - 1];
      let histogram_last_val = histogram_last['factor_data-left'].toFixed(2);
      this.histogram_last_val = histogram_last_val;

      // 频率直方图
      this.initChart8_1_arr1 = arr1;
      this.initChart8_1_lastday = lastday;

      this.$nextTick(() => {
        this.initChart8_1();
        this.initChart4();
      });
      // this.initChart6();
    },
    initChart4(val) {
      if (!val) {
        this.chartColumn4 = this.$echarts.init(document.querySelectorAll('#chartColumn4')[0]);
      } else {
        this.chartColumn4 = this.$echarts.init(document.querySelectorAll('#chartColumn4')[1]);
      }

      // this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4);
      this.chartColumn4.clear();
      let dValuationAnalysisSingle = this.dValuationAnalysisSingle;
      let dValuationAnalysisSingle_X = this.dValuationAnalysisSingle_X;
      let dValuationAnalysisSingle_YL = this.dValuationAnalysisSingle_YL;
      let dValuationAnalysisSingle_YR = this.dValuationAnalysisSingle_YR;
      let dValuationAnalysisSingle_IPO = this.dValuationAnalysisSingle_IPO;
      // ipo
      let arr1 = dValuationAnalysisSingle_IPO.map((node) => {
        return node.IPO_date;
      });

      arr1 = [...new Set(arr1)];

      let markPointData = arr1.map((node) => {
        let obj = {
          yAxis: 0,
          xAxis: node,
        };
        return obj;
      });

      let dValuationAnalysisSingle_pic1_lines = this.dValuationAnalysisSingle_pic1_lines;

      let offsetNum = 180;

      // 分位线，标准差文案
      let text1 = this.show_mode_chart4 == 'std' ? '标准差（+1）' : '80分位数';
      let text2 = this.show_mode_chart4 == 'std' ? '标准差（-1）' : '20分位数';
      let factor_chart4 = this.factor_chart4;

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let option = {
        grid: {
          top: 70,
          bottom: 20,
          right: 50,
          left: 30,
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove|click',
          formatter: function (args) {
            let str = '';
            str += `${args[0].axisValueLabel}`;
            str += '<br>';
            args.forEach((node, idx) => {
              if (idx) {
                let num = (parseInt(Math.abs(node.value)) + '').length >= 2 ? 1 : 2;
                let val = Number(node.value).toFixed(num);
                str += `${node.marker}${node.seriesName}：${val}`;
              } else {
                if (factor_chart4 == 'div_yield_ttm' || factor_chart4 == 'implied_cap_rate') {
                  let zhi = Number(node.value) * 100;
                  let num = (parseInt(Math.abs(zhi)) + '').length >= 2 ? 1 : 2;
                  let val = Number(zhi).toFixed(num);
                  str += `${node.marker}${node.seriesName}：${val}%`;
                } else {
                  let num = (parseInt(Math.abs(node.value)) + '').length >= 2 ? 1 : 2;
                  let val = Number(node.value).toFixed(num);
                  str += `${node.marker}${node.seriesName}：${val}`;
                }
              }

              str += '<br>';

              return str;
            });
            return str;
          },
        },

        legend: [
          {
            show: true,
            itemWidth: 15,
            itemHeight: 15,
            data: [
              {
                // name: "PB",
                name:
                  this.factor_chart4 === 'div_yield_ttm'
                    ? '分红回报率（左轴）'
                    : this.factor_chart4 === 'implied_cap_rate'
                    ? '隐含Cap Rate（左轴）'
                    : this.factor_chart4 + '（左轴）',
                icon: 'circle',
                //  color:'rgbargba(86, 84, 202, 1)',
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 20,
            y: 20,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 5,
            data: [
              {
                name: '指数点位（右轴）',
                icon: 'roundRect',
                color: 'rgba(56, 56, 118, 1)',
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 180,
            y: 20,
          },

          {
            show: true,
            itemWidth: 15,
            itemHeight: 15,
            data: [
              {
                name: 'IPO标志',
                icon: 'triangle',
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 220 + offsetNum,
            y: 20,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 5,
            data: [
              {
                name: text1,
                lineStyle: {
                  type: 'dotted',
                  width: 3,
                },
                //  color:'rgbargba(86, 84, 202, 1)',
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 350 + offsetNum,
            y: 20,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 5,
            data: [
              {
                name: '平均值',
                icon: 'roundRect',
                //  color:'rgbargba(86, 84, 202, 1)',
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 490 + offsetNum,
            y: 20,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 5,
            data: [
              {
                name: text2,
                lineStyle: {
                  type: 'dotted',
                  width: 3,
                },
                //  color:'rgbargba(86, 84, 202, 1)',
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,

              fontSize: 14,
            },
            left: 590 + offsetNum,
            y: 20,
          },
        ],

        xAxis: {
          type: 'category',
          // boundaryGap: true,
          boundaryGap: false,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 50,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: dValuationAnalysisSingle_X,
          // data: ['1','2','3','4'],
        },
        yAxis: [
          {
            scale: true,
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: 'left',
            axisLabel: {
              fontSize: 14,
              show: true,

              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (factor_chart4 == 'div_yield_ttm' || factor_chart4 == 'implied_cap_rate') {
                  args = args * 100;
                  let num = fnGetMuchDecimals(args);
                  return args.toFixed(num) + '%';
                } else {
                  return args;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: 'right',
            axisLabel: {
              fontSize: 14,
              show: true,

              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                let num = (parseInt(Math.abs(args)) + '').length >= 2 ? 1 : 2;
                return args.toFixed(num);
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],

        series: [
          {
            // data: [29000, 33000, 34000, 34100],
            name:
              this.factor_chart4 === 'div_yield_ttm'
                ? '分红回报率（左轴）'
                : this.factor_chart4 === 'implied_cap_rate'
                ? '隐含Cap Rate（左轴）'
                : this.factor_chart4 + '（左轴）',
            data: dValuationAnalysisSingle_YL,
            yAxisIndex: 0,
            type: 'line',
            areaStyle: {
              color: 'rgba(93, 204, 200, 1)',
            },

            itemStyle: {
              color: 'rgba(93, 204, 200, 1)',
            },
            symbol: 'none',
          },

          {
            name: '指数点位（右轴）',
            data: dValuationAnalysisSingle_YR,
            yAxisIndex: 1,
            type: 'line',

            itemStyle: {
              color: 'rgba(86, 84, 202, 1)',
            },
            symbol: 'none',
          },

          {
            name: 'IPO标志',
            yAxisIndex: 1,
            type: 'line',
            itemStyle: {
              color: 'rgba(212, 48, 48, 1)',
            },

            symbol: 'none',
            markPoint: {
              // 标记的数据，可以是最大值最小值也可以是自定义的坐标
              data: markPointData,
              symbol: 'triangle', // 标记图形
              symbolSize: 18,
              symbolOffset: [0, '50%'],

              label: {
                show: false,
              },
              emphasis: {
                label: {
                  show: true,
                  // color:'#7c7c7c',
                  // color:'#fff',
                  color: OPTIONDATA.title.textStyle.color,
                  position: 'top',
                  align: 'center',
                  backgroundColor: 'Transparent',
                  padding: [0, 0, 0, 0],
                  borderRadius: 3,
                  fontSize: 14,
                  // width:90,height:90,
                  formatter: function (val) {
                    let name = val.name;
                    let arr = dValuationAnalysisSingle_IPO.filter((node) => {
                      return node.IPO_date == name;
                    });

                    let str = '';
                    str += `${arr[0].IPO_date} `;
                    str += '\n';

                    arr.forEach((node, idx) => {
                      //  str += `<span style=\"font-weight: bolder;\">${node.IPO_date}</span>`;
                      if (arr.length > 1) {
                        // str+='IPO: '+
                        str += 'IPO：';
                        str += `{d|${node.REITs_name}}`;
                        str += '\n';
                      } else {
                        str += 'IPO：';
                        str += `  {d|${node.REITs_name}}`;
                        // str+='IPO: '+ node.REITs_name
                      }
                    });

                    //  str += `${arr[0].IPO_date} `;

                    return str;
                  },
                  rich: {
                    b: {
                      color: 'red',
                    },
                  },
                },
              },

              // 标注点的样式
              itemStyle: {
                color: 'rgba(212, 48, 48, 1)', // 标注点颜色
                label: {
                  show: true,
                },
              },
            },
          },

          {
            name: '平均值',
            yAxisIndex: 0,
            data: dValuationAnalysisSingle_pic1_lines.line.avg,
            type: 'line',
            itemStyle: {
              color: theme == 'dark' ? 'rgba(255, 255, 255, 1)' : '#878787',
            },
            markLine: {
              symbol: ['none', 'none'],

              data: [
                {
                  yAxis: dValuationAnalysisSingle_pic1_lines.line.avg,
                },
              ],

              lineStyle: {
                type: 'solid',
                color: theme == 'dark' ? 'rgba(255, 255, 255, 1)' : '#878787',
                width: 1,
              },

              label: {
                show: false,
              },
            },
          },

          {
            name: text1,
            yAxisIndex: 0,
            type: 'line',
            data: dValuationAnalysisSingle_pic1_lines.line.upper,
            itemStyle: {
              color: 'rgba(255, 174, 88, 1)',
            },

            markLine: {
              symbol: ['none', 'none'],

              data: [
                {
                  yAxis: dValuationAnalysisSingle_pic1_lines.line.upper,
                },
              ],

              lineStyle: {
                type: 'dashed',
                color: 'rgba(255, 174, 88, 1)',
                width: 1,
              },

              label: {
                show: false,
              },
            },
          },

          {
            name: text2,
            yAxisIndex: 0,
            type: 'line',
            itemStyle: {
              color: 'rgba(121, 157, 255, 1)',
            },

            markLine: {
              symbol: ['none', 'none'],

              data: [
                {
                  yAxis: dValuationAnalysisSingle_pic1_lines.line.lower,
                },
              ],

              lineStyle: {
                type: 'dashed',
                color: 'rgba(121, 157, 255, 1)',
                width: 1,
              },

              label: {
                show: false,
              },
            },
          },
        ],
      };

      this.chartColumn4.setOption(option);
      this.chartColumn4.resize();
      this.loading_chart4 = false;
      // this.funResize()
    },

    // chart5
    fnCTimeButtons_select_val_three(val) {
      this.start_date_chart5 = val;
      this.fnGetValuationAnalysisSingle2();
    },
    CotherButtons_select_val(val) {
      this.other_chart1 = val;
      this.fnGetValuationAnalysisSingle2();
    },

    async fnGetValuationAnalysisSingle2() {
      let r_type = this.r_type_chart4;
      let factor = this.factor_chart4;
      let start_date = this.start_date_chart4;

      let n = this.other_chart1;
      const { data } = await this.$https.get(
        '/api/valuation_analysis_single_data2?r_type=' +
          r_type +
          '&factor=' +
          factor +
          '&start_date=' +
          start_date +
          '&n=' +
          n
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      this.last_red_line = data.data.last_red_line;

      let arr1 = data.data.scatter;

      let arr2 = arr1.map((item) => {
        return [item.x, item.y, item.tr_date];
      });

      arr1 = arr1.map((item) => {
        return [item.x, item.y];
      });

      this.dValuationAnalysisSingle2 = arr1;
      this.chart5_arr2 = arr2;
      this.$nextTick(() => {
        this.initChart5();
      });
    },
    initChart5() {
      this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5);
      this.chartColumn5.clear();

      let arr2 = this.chart5_arr2;

      let dValuationAnalysisSingle2 = this.dValuationAnalysisSingle2;
      let arr = dValuationAnalysisSingle2.map((node) => {
        return node[0];
      });
      let last_red_line = this.last_red_line;

      let min = Math.min(...arr);
      let max = Math.max(...arr);
      let arr3 = [min, max, last_red_line];

      let minVal = Math.min(...arr3);
      let maxVal = Math.max(...arr3);

      echarts.registerTransform(ecStat.transform.regression);

      let factor_chart4 = this.factor_chart4;
      const name_map = { implied_cap_rate: '隐含Cap Rate', div_yield_ttm: '分红回报率' };

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let option = {
        dataset: [
          {
            source: dValuationAnalysisSingle2,
          },
          {
            transform: {
              type: 'ecStat:regression',
              // 'linear' by default.
              // config: { method: 'linear', formulaOn: 'end'}
            },
          },
        ],
        title: {
          // 标题设置
          text: '收\n\n益\n\n率', // 标题文字
          textStyle: {
            // 标题文字样式设置
            // color: "#fff",
            color: OPTIONDATA.title.textStyle.color,
            fontSize: 14,
          },
          left: 10,
          top: 'middle',
        },
        grid: {
          top: 80,
          bottom: 30,
          left: 35,
          containLabel: true,
        },
        legend: {
          orient: 'horizontal',
          left: 20,
          itemWidth: 20,
          itemHeight: 10,
          top: 25,
          textStyle: {
            // color: "#fff",
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: 14,
          },
          data: [
            {
              // name: this.factor_chart4 == 'div_yield_ttm' ? '分红回报率' : this.factor_chart4,
              name: name_map[this.factor_chart4] || this.factor_chart4,
              icon: 'roundRect',
            },
          ],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              show: false,
            },
          },
          triggerOn: 'mousemove|click',
          formatter: (args) => {
            let str = '';

            args.forEach((node, idx) => {
              if (!idx) {
                let index = node.dataIndex;
                str += `日期：${arr2[index][2]}`;
                str += '<br>';
                let x = arr2[index][0];

                if (node.seriesName == '分红回报率' || node.seriesName == '隐含Cap Rate') {
                  x = Number(x) * 100;
                  let num = parseInt(Math.abs(x) + '').length >= 2 ? 1 : 2;
                  x = x.toFixed(num) + '%';
                } else {
                  let num = parseInt(Math.abs(x) + '').length >= 2 ? 1 : 2;
                  x = x.toFixed(num);
                }

                str += `${node.marker}${name_map[this.factor_chart4] || this.factor_chart4}：${x}`;
                str += '<br>';
                let y = arr2[index][1] * 100;
                let num2 = parseInt(Math.abs(y) + '').length >= 2 ? 1 : 2;
                y = y.toFixed(num2);
                str += `${node.marker}一个月后回报率：${y}%`;
                return str;
              }
            });
            return str;
          },
        },
        xAxis: {
          type: 'value',
          scale: true,
          splitNumber: 3,
          min: minVal.toFixed(2),
          max: maxVal.toFixed(2),
          // max: (Number(last_red_line)*1.001).toFixed(2),
          axisLine: {
            show: false,
            lineStyle: {
              color: '#dcdcdd',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            // color: "rgba(255, 255, 255, 0.85)",
            color: OPTIONDATA.xAxis.axisLabel.color,
            formatter: function (args) {
              if (factor_chart4 == 'div_yield_ttm' || factor_chart4 == 'implied_cap_rate') {
                args = args * 100;
                let num = fnGetMuchDecimals(args);
                return args.toFixed(num) + '%';
              } else {
                return args;
              }
              console.log(val, '等i去');
              return Number(val).toFixed(2);
            }, //y轴百分比
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        yAxis: {
          type: 'value',
          scale: true,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#dcdcdd',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            formatter: function (val) {
              val = val * 100;
              val = val.toFixed(1);

              if (val < 0) {
                if (String(val).length == 4) {
                  val = val + '0';
                } else {
                  val = val;
                }
              } else {
                if (String(val).length == 3) {
                  val = val + '0';
                } else {
                  val = val;
                }
              }

              val = val + '%';

              return val;
            },
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },

        series: [
          {
            name: name_map[this.factor_chart4] || this.factor_chart4,
            data: dValuationAnalysisSingle2,
            type: 'scatter',
            symbolSize: 13,
            cursor: 'pointer',
            itemStyle: {
              color: 'rgba(90, 202, 198, 1)',
            },
            markLine: {
              symbol: ['none', 'none'],
              data: [
                {
                  xAxis: last_red_line,
                },
              ],
              lineStyle: {
                type: 'solid',
                color: 'rgba(255, 0, 0, 1)',
                width: 2,
              },
              label: {
                show: false,
              },
              emphasis: {
                label: {
                  show: true,
                  color: 'red',
                  fontSize: 14,
                  formatter: function (val) {
                    return val.value;
                  },
                },
              },
            },
          },
          {
            name: '线性回归',
            type: 'line',
            datasetIndex: 1,
            symbolSize: 0.1,
            symbol: 'circle',
            itemStyle: {
              type: 'dashed',
              color: theme == 'dark' ? '#fff' : '#878787',
            },
            label: { show: false, fontSize: 16 },
            labelLayout: { dx: -20 },
            encode: { label: 2, tooltip: 1 },
          },
        ],
      };
      this.chartColumn5.setOption(option);
      this.chartColumn5.resize();
      // this.funResize()
    },

    initChart6() {
      // 废弃
      // this.chartColumn6 = this.$echarts.init(this.$refs.chartColumn6);

      let dhistogramData_X = this.dhistogramData_X;
      let dhistogramData_Y = this.dhistogramData_Y;
      let histogram_last_val = this.histogram_last_val;

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        title: {
          // 标题设置
          text: '频率分布直方图qq', // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,

            fontSize: 16,
          },
          top: 15,
          left: 'center',
        },
        grid: {
          top: 80,
          bottom: 20,
          left: 20,
          right: 20,
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove|click',
        },
        legend: {
          show: true,
          orient: 'horizontal',
          left: 20,
          top: 30,
          align: 'left',
          // x:50,
          // x2: 150,
          icon: 'roundRect',
          itemGap: 10,
          itemWidth: 24,
          itemHeight: 12,
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: 'inherit',
          },
          textStyle: {
            color: '#fff',
            fontSize: 14,
          },
          data: [this.factor_chart4],
          // color: ['#c23531','#5754f7','#a84d38','#ff7432']
        },
        // tooltip: {
        //   // 工具提示
        //   // trigger:'item',
        //   trigger: "axis", // 工具提示的类型 item代表的是每个柱本身, axis代表的是坐标轴
        //   triggerOn: "click", // 触发时机, click代表点击, mouseOver代表鼠标移过
        //   formatter: function (args) {
        //     // 文字格式化
        //     return args[0].name + "的YTD回报率：" + args[0].data + "%";
        //   },
        // },
        xAxis: {
          type: 'category',
          data: dhistogramData_X,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#dcdcdd',
            },
            onZero: false, //轴线是否在0刻度轴上
            // onZero: true, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: 'rgba(255, 255, 255, 0.85)',
          },
        },
        yAxis: {
          scale: true,
          type: 'value',
          axisLine: {
            show: false,
            lineStyle: {
              color: '#dcdcdd',
            },
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            formatter: function (val) {
              val = val.toFixed(1);
              if (String(val).length == 3) {
                val = val + '0';
              } else {
                val = val;
              }
              val = val + '%';
              return val;
            },
            color: 'rgba(255, 255, 255, 0.85)',
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#48494c',
            },
          },
        },
        series: [
          {
            name: this.factor_chart4,
            type: 'bar',
            data: dhistogramData_Y,
            itemStyle: {
              color: 'rgba(90, 202, 198, 1)',
            },
            barWidth: '35px',
            markLine: {
              symbol: ['none', 'none'],
              data: [
                {
                  xAxis: histogram_last_val,
                },
              ],
              lineStyle: {
                type: 'solid',
                color: 'rgba(255, 0, 0, 1)',
                width: 2,
              },
              label: {
                show: false,
              },
            },
            label: {
              show: true,
              position: 'top',
              // fontSize: 14,
              formatter: function (value) {
                if (value.name == '公募REITs') {
                  return `{a|${value.data}%}  {val|YTD回报率}`;
                } else {
                  return '';
                }
              },
              rich: {
                a: {
                  color: '#d43030',
                  fontSize: 24,
                  fontWeight: 'bolder',
                },
                val: {
                  color: '#bababa',
                  fontSize: 14,
                  verticalAlign: 'bottom',
                },
              },
            },
          },
          {
            // data: [29000, 33000, 34000, 34100],
            data: dhistogramData_Y,
            type: 'line',
            smooth: false,
            itemStyle: {
              color: '#fff',
            },
            symbol: 'none',
          },
        ],
      };

      // this.chartColumn6.setOption(option);
      // this.chartColumn6.resize();
      // this.funResize()
    },

    // chart7
    fnGetCbtnsSep_chart5(val) {
      this.dButtons_separrate_current_chart5 = val;

      this.fnGetValuationBandByType();
    },
    fnCSelect_select_val_three(val) {
      this.valuation_chart7 = val;
      this.fnGetValuationBandByType();
    },
    fnCCascader_select_val_three(val) {
      this.r_type_chart7 = val;
      this.fnGetValuationBandByType();
    },
    fnCTimeButtons_select_val_LISHISHUJU(val) {
      this.start_date_LISHISHUJU = val;
      this.$nextTick(() => {
        this.initChart7();
      });
    },

    async fnGetValuationBandByType() {
      this.loading = true;
      // let r_type_ = this.r_type_chart7?this.r_type_chart7:'公募REITs';
      // let valuation = this.valuation_chart7?this.valuation_chart7:'PB';

      let r_type_ = this.r_type_chart7;
      let valuation = this.valuation_chart7;

      const { data } = await this.$https.get(
        '/api/valuation_band_by_type?r_type_=' + r_type_ + '&valuation=' + valuation
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      if (data.data.length == 0) {
        this.loading = false;
        this.DATA_chart7 = [];
        this.DATA_chart7ba = [];
        this.chartColumn7.clear();
        return;
      }

      let DATA = data.data;
      this.DATA_chart7 = DATA;
      this.DATA_chart7ba = DATA.slice();

      // 下载 start
      this.json_fields_column7 = {};

      this.DATA_chart7ba = this.DATA_chart7ba.map((node, idx) => {
        let keys = Object.keys(node);
        let values = Object.values(node);
        keys = keys.map((item) => {
          if (item.includes('.')) {
            item = item.toString().replace(/[.,]/g, '');
          }
          return item;
        });
        let obj = {};
        keys.forEach((ele, idx) => {
          obj[ele] = values[idx];
        });

        return obj;
      });

      let keys = Object.keys(this.DATA_chart7[0]);
      let keys2 = Object.keys(this.DATA_chart7ba[0]);

      this.json_fields_column7 = {
        日期: 'tr_date',
        收盘价: 'current',
      };

      keys.forEach((node) => {
        let val = '';
        if (node.includes('.')) {
          val = node.toString().replace(/[.,]/g, '');
          let obj = keys2.find((ele) => ele == val);
          if (obj) {
            this.json_fields_column7[node] = obj;
          }
        }
      });

      // 时间筛选
      let levelName = window.sessionStorage.getItem('levelName');
      if (levelName == '普通客户') {
        let result = fnComputeTime('3M');
        this.DATA_chart7ba = this.DATA_chart7ba.filter((node) => {
          return node['tr_date'] >= result;
        });
      } else {
        let result = fnComputeTime('6M');
        this.DATA_chart7ba = this.DATA_chart7ba.filter((node) => {
          return node['tr_date'] >= result;
        });
      }

      // 下载 end

      this.$nextTick(() => {
        this.initChart7();
      });

      return;
    },
    initChart7(val) {
      if (this.DATA_chart7.length == 0) {
        return;
      }
      if (!val) {
        this.chartColumn7 = this.$echarts.init(document.querySelectorAll('#chartColumn7')[0]);
      } else {
        this.chartColumn7 = this.$echarts.init(document.querySelectorAll('#chartColumn7')[1]);
      }
      this.chartColumn7.clear();

      // 对DATA进行切分

      let time = this.start_date_LISHISHUJU;
      let DATA = this.DATA_chart7;
      let timeRes = this.fnComputeTime(time);

      // 裁切数组
      if (timeRes !== null) {
        DATA = DATA.filter((node) => {
          return node.tr_date >= timeRes;
        });
      }

      let dValuationBandByType_X = DATA.map((node) => {
        return node.tr_date;
      });

      let data1 = Object.keys(DATA[0]);
      data1.shift();
      data1.slice(0, 20);

      let seriesArr = [];
      data1.forEach((node) => {
        let obj = {
          name: node,
          data: [],
        };

        DATA.forEach((e) => {
          obj.data.push(e[node]);
        });

        seriesArr.push(obj);
      });

      seriesArr.unshift(seriesArr.pop());

      let NAME = seriesArr.map((node) => {
        if (node.name == 'current') {
          return '收盘价';
        } else {
          return node.name;
        }
      });

      NAME = NAME.filter((node) => {
        return node !== '收盘价';
      });

      NAME.unshift('收盘价');

      let seriesARRAY = seriesArr.map((node) => {
        return {
          name: node.name == 'current' ? '收盘价' : node.name,
          data: node.data,
          type: 'line',
          smooth: this.dButtons_separrate_current_chart5 ? true : false,
          symbol: 'none',
          lineStyle: {
            width: node.name == 'current' ? 3 : 2,
          },

          label: {
            show: false,
            position: 'top',
          },
        };
      });

      let valuation_chart7 = this.valuation_chart7;

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let option = {
        title: [
          {
            text: '备注：隐含Cap Rate为推算NOI_ttm与总体价值ev(市值+负债余额)计算得到',
            left: '0.7%',
            top: '95%',
            textStyle: {
              fontSize: 12,
              color: '#9e9e9e',
            },
          },
        ],
        legend: {
          show: true,
          orient: 'vertical',
          right: 20,
          top: 'middle',
          align: 'left',
          // x:50,
          // x2: 150,
          icon: 'roundRect',
          itemGap: 20,
          itemWidth: 45,
          itemHeight: 7,
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: 'inherit',
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: 16,
          },
          data: NAME,
        },

        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove|click',
          formatter: function (args) {
            let str = '';
            str += `${args[0].axisValueLabel}`;
            args.forEach((node) => {
              let zhi = Number(node.value);
              if (valuation_chart7 == 'div_yield') {
                let val = Number(zhi).toFixed(2) + '%';
                str += '<br>';
                str += `${node.marker}${node.seriesName}：${val}`;
              } else {
                let val = Number(zhi).toFixed(2);
                str += '<br>';
                str += `${node.marker}${node.seriesName}：${val}`;
              }

              return str;
            });
            return str;
          },
        },

        grid: {
          left: 20,
          top: 20,
          bottom: 35,
          right: 135,
          containLabel: true,
        },
        color: [
          // "#ffffff",
          theme == 'dark' ? '#fff' : '#878787',
          '#5e5ce6',
          '#5acac6',
          '#608fe5',
          '#dda579',
          '#c24492',
        ],

        xAxis: {
          type: 'category',
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            margin: 20,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
          data: dValuationBandByType_X,
        },
        yAxis: {
          scale: true,
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              if (valuation_chart7 == 'div_yield') {
                let num = (parseInt(Math.abs(args)) + '').length >= 2 ? 1 : 2;
                return args.toFixed(num) + '%';
              } else {
                let num = (parseInt(Math.abs(args)) + '').length >= 2 ? 1 : 2;
                return args.toFixed(num);
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: seriesARRAY,
      };

      this.chartColumn7.setOption(option);
      this.chartColumn7.resize();
      this.loading = false;
    },

    initChart8_1() {
      this.chartColumn6 = this.$echarts.init(this.$refs.chartColumn6);
      this.chartColumn6.clear();

      let data = this.initChart8_1_arr1;
      let lastday = this.initChart8_1_lastday;

      let factor_chart4 = this.factor_chart4;

      echarts.registerTransform(ecStat.transform.histogram);
      let dhistogramData_Y = this.dhistogramData_Y;

      let data2 = [7, 6, 9, 2, 6, 17, 3, 5, 1];
      let arr2 = this.fnCountHMD(data2, 10);
      let source = data;
      source = source.map((node) => {
        return Number(node);
      });

      var bins = ecStat.histogram(source, 'sturges');
      //
      let idx = bins.bins.findIndex((node) => {
        return node.sample.some((item) => item == lastday);
      });

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      const name_map = { implied_cap_rate: '隐含Cap Rate', div_yield_ttm: '分红回报率' };

      var option = {
        title: {
          // 标题设置
          text: '频率分布直方图', // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: 16,
          },
          top: 15,
          left: 'center',
        },
        grid: {
          top: 80,
          bottom: 20,
          left: 20,
          right: 20,
          containLabel: true,
        },
        legend: {
          show: true,
          orient: 'horizontal',
          left: 20,
          top: 30,
          align: 'left',
          // x:50,
          // x2: 150,
          icon: 'roundRect',
          itemGap: 10,
          itemWidth: 24,
          itemHeight: 12,
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: 'inherit',
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: 14,
          },
          data: [name_map[this.factor_chart4] || this.factor_chart4],
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove|click',
        },
        xAxis: {
          type: 'category',
          scale: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#dcdcdd',
            },
            onZero: false, //轴线是否在0刻度轴上
            // onZero: true, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
            formatter: function (args) {
              if (factor_chart4 == 'div_yield_ttm' || factor_chart4 == 'implied_cap_rate') {
                args = args * 100;
                let num = fnGetMuchDecimals(args);
                return args.toFixed(num) + '%';
              } else {
                return args;
              }
            },
          },
        },
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false,
              lineStyle: {
                color: '#dcdcdd',
              },
            },
            axisTick: {
              show: false,
              //x轴刻度相关设置
              alignWithLabel: true,
            },
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: OPTIONDATA.splitLine.lineStyle.color,
              },
            },
          },
        ],
        series: [
          {
            yAxisIndex: 0,
            name: name_map[this.factor_chart4] || this.factor_chart4,
            type: 'bar',
            barWidth: '30px',
            label: {
              show: false,
              position: 'top',
              textStyle: {
                color: '#fff',
              },
            },
            itemStyle: {
              color: 'rgba(90, 202, 198, 1)',
            },
            data: bins.data,
            markLine: {
              symbol: ['none', 'none'],
              data: [
                {
                  xAxis: idx,
                },
              ],
              lineStyle: {
                type: 'solid',
                color: 'rgba(255, 0, 0, 1)',
                width: 2,
              },
              label: {
                show: false,
              },
              emphasis: {
                label: {
                  show: false,
                  color: 'red',
                  fontSize: 14,
                  formatter: function (val) {
                    return val.value;
                  },
                },
              },
            },
            // datasetIndex: 1
          },
          // {
          //      yAxisIndex: 1,
          // name: '核密度',
          // symbol: 'none',
          // data: arr2,
          // type: 'line',
          // }
        ],
      };
      this.chartColumn6.setOption(option);
      this.chartColumn6.resize();
      // this.funResize()
    },

    // 计算核密度
    fnCountHMD(data, bandwidth) {
      // 高斯核函数
      function gaussianKernel(x) {
        return (1 / Math.sqrt(2 * Math.PI)) * Math.exp(-0.5 * Math.pow(x, 2));
      }

      // 计算核密度曲线
      function calculateKernelDensity(data, bandwidth) {
        const kernelDensity = [];
        const n = data.length;

        for (let i = 0; i < n; i++) {
          let sum = 0;
          const xi = data[i];

          for (let j = 0; j < n; j++) {
            const xj = data[j];
            const diff = (xi - xj) / bandwidth;
            sum += gaussianKernel(diff);
          }

          const density = sum / (n * bandwidth);
          kernelDensity.push(density);
        }

        return kernelDensity;
      }

      const kernelDensity = calculateKernelDensity(data, bandwidth);

      return kernelDensity;
    },

    // 计算时间范围
    fnComputeTime(TIME) {
      let date = new Date();
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + ' ';

      if (TIME == '3M') {
        if (date.getMonth() + 1 - 3 >= 1) {
          M =
            (date.getMonth() + 1 - 3 < 10
              ? '0' + (date.getMonth() + 1 - 3)
              : date.getMonth() + 1 - 3) + '-';
        } else {
          Y = date.getFullYear() - 1 + '-';
          M =
            (12 - 3 + date.getMonth() + 1 < 10
              ? '0' + (12 - 3 + date.getMonth() + 1)
              : 12 - 3 + date.getMonth() + 1) + '-';
        }
      }

      if (TIME == '6M') {
        if (date.getMonth() + 1 - 6 >= 1) {
          M =
            (date.getMonth() + 1 - 6 < 10
              ? '0' + (date.getMonth() + 1 - 6)
              : date.getMonth() + 1 - 6) + '-';
        } else {
          Y = date.getFullYear() - 1 + '-';
          M =
            (12 - 6 + date.getMonth() + 1 < 10
              ? '0' + (12 - 6 + date.getMonth() + 1)
              : 12 - 6 + date.getMonth() + 1) + '-';
        }
      }

      if (TIME == 'YTD') {
        M = '01' + '-';
        D = '01';
      }

      if (TIME == '1Y') {
        Y = date.getFullYear() - 1 + '-';
      }

      if (TIME == '3Y') {
        Y = date.getFullYear() - 3 + '-';
      }

      let result = '';
      if (TIME == 'MAX') {
        result = null;
      } else {
        result = Y + M + D;
      }

      return result;
    },

    async handleClick(e) {
      this.activeName = e.name;
      localStorage.setItem('activeName', this.activeName);
      let activeName = e.name;
      console.log('handleClick', activeName);

      if (activeName == 'first') {
        this.$eventBus.$emit('setBreadcrumbs', '概览');
        setTimeout(() => {
          this.$refs.summer.handleClick(e);
        }, 200);
      }

      if (activeName == 'second') {
        this.$eventBus.$emit('setBreadcrumbs', '动态估值');
        this.fnGetValuationTable();
      }

      if (activeName == 'third') {
        this.$eventBus.$emit('setBreadcrumbs', '历史估值');
        this.fnGetValuationAnalysisSingle();
        this.fnGetValuationAnalysisSingle2();
      }

      if (activeName == 'fourth') {
        this.$eventBus.$emit('setBreadcrumbs', '估值Bands');
        this.fnGetValuationBandByType();
      }

      if (activeName == 'fifth') {
        setTimeout(() => {
          this.$refs.irr.handleClick(e);
        }, 200);
        // this.isVip = fnGetLevelName() == 'VIP客户' ? true : false;
        // this.$eventBus.$emit('setBreadcrumbs', '估值财报分析');
        // if (!this.isVip) return;
        // this.fnGetCalIrr();
        // this.fnGetCalIrrSingle();
      }
    },

    // 计算L1 Y轴
    fnCalL1() {},
  },
  filters: {
    isZero: function (val) {
      let value = val.substring(0, val.length - 1);
      if (value == 0) {
        return '';
      } else {
        return val;
      }
    },
  },
  components: {
    CSelect,
    CCategoryButton,
    CCascader,
    CButtonsSeparate,
    CTimeButtons,
    CotherButtons,
    CButtonsDownload,
    CDialog,
    summer,
    irr,
  },
  computed: {
    cssVars() {
      return {
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
        '--TABPANEHEIGHT': this.TABPANEHEIGHT + 'px',
        '--GMREITs': this.GMREITs,
        '--CQLREITs': this.CQLREITs,
        '--JYQLREITs': this.JYQLREITs,
        '--WLCC': this.WLCC,
        '--CYY': this.CYY,
        '--BZXZF': this.BZXZF,
        '--XF': this.XF,
        '--SZST': this.SZST,
        '--NY': this.NY,
        '--SFGL': this.SFGL,
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.styleBindFun();
      this.fnGetTheme();
      fnDownloadBgc();
      this.$nextTick(() => {
        if (this.activeName == 'third') {
          this.initChart4();
          this.initChart5();
          this.initChart8_1();
        }
        if (this.activeName == 'fourth') {
          this.initChart7();
        }
      });
      return;
    },
  },
};
</script>

<style scoped>
::v-deep.el-table .cell {
  padding: 0;
  padding-left: 6px;
}

.openBtn {
  background-color: var(--primary-color) !important;
  color: #333333 !important;
  border: none !important;
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.openBtn:hover {
  background-color: var(--primary-color) !important;
  color: #333333 !important;
  border: none !important;
  background-size: contain;
}

.btnArea_center {
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
  width: 100%;
}

.container {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  /* line-height: calc(var(--ROOTFONTFIZE) * (20 / 144)); */
  height: 100%;
  /* padding-right: 20px; */
  color: rgba(255, 255, 255, 1);
  /* height: auto; */
}

.top {
  width: 100%;
  height: calc(var(--ROOTFONTFIZE) * (470 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background: rgba(29, 31, 37, 1);
}

.bottom {
  display: flex;
  margin-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.bottom .left,
.bottom .right {
  flex: 1;
  height: calc(var(--ROOTFONTFIZE) * (430 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background: rgba(29, 31, 37, 1);
}

.bottom .right {
  margin-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.tit {
  margin-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid rgba(21, 115, 254, 1);
  padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
}

.other_chart1_style {
  display: flex;
  margin-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.other_chart1_style div:nth-child(1) {
  flex: 3;
  margin-right: calc(var(--ROOTFONTFIZE) * (30 / 144));
  display: flex;
  justify-content: center;
}

.other_chart1_style div:nth-child(2) {
  flex: 6;
}

.other_chart1 {
  /* width: 60%; */
}

/* ------------------------------------------------------------------ */
.card {
  /* background-color: rgba(29, 31, 37, 1); */
  background-color: var(--charts_bgc);
  height: auto;
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  /* margin-top: calc(var(--ROOTFONTFIZE) * (5 / 144)); */
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  overflow: hidden;
}

.card_tit {
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: var(--chart_title);
  /* border-left: 5px solid rgba(21, 115, 254, 1); */
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
  padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
  display: flex;
  justify-content: space-between;
}

.card_tit2 {
  display: flex;
  justify-content: end;
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.card .main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  overflow-x: hidden;
  height: 100%;
  position: relative;
}

.hasMore {
}

.hasMore:hover .btnMo,
.hasMore:hover .leftBtn {
  opacity: 1;
  cursor: pointer;
}

.btnMo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 18%;
  width: auto;
  padding: 0 30px;
  display: flex;
  align-items: center;
  opacity: 0;
  background-color: rgba(127, 127, 127, 0.1);
  transition: opacity 0.5s;
}

.OVERVIEWICONSTYLE {
  background-color: #383838;
  color: #f7ecdc;
  font-size: 25px;
  padding: 10px;
  border-radius: 50%;
}

/* .btnMo:hover,
.leftBtn:hover {
  opacity: 1;
  cursor: pointer;
} */

.leftBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0 !important;
  height: 18%;
  width: auto !important;
  padding: 0 30px;
  display: flex;
  align-items: center;
  opacity: 0;
  background-color: rgba(127, 127, 127, 0.1);
  transition: opacity 0.5s;
}

.specialMain {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.card .main .main_item {
  width: 30%;
  height: calc(var(--ROOTFONTFIZE) * (300 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
  /* background-color: #1a1c21; */
  background-color: var(--charts_bgc);
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  /* border: 0.2px solid #35373b; */
  border: 1px solid var(--charts_brc);
  /* margin-left: 20px; */
}

.h400 {
  height: 400px !important;
}

.main_item_nothing {
  background-color: transparent !important;
  border: none !important;
}

.card .main .main_item:nth-child(n + 4) {
  height: calc(var(--ROOTFONTFIZE) * (420 / 144));
}

.main_item_first {
  margin: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-bottom: calc(var(--ROOTFONTFIZE) * (2 / 144)) dotted rgba(118, 118, 118, 0.48);
}

.main_item_second {
  margin: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.main_item_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 600;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (18.96 / 144));
  color: var(--legndAreaItem_color);
  margin-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (5 / 144));
  padding: 0 calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.main_item_top div span {
  color: var(--GMREITs);
}

.CQL_color {
  color: var(--CQLREITs) !important;
}

.JYQL_color {
  color: var(--JYQLREITs) !important;
}

.WLCH_color {
  color: var(--WLCC) !important;
}

.CYY_color {
  color: var(--CYY) !important;
}

.BZXZF_color {
  color: var(--BZXZF) !important;
}

.XF_color {
  color: var(--XF) !important;
}

.SZST_color {
  color: var(--SZST) !important;
}

.NY_color {
  color: var(--NY) !important;
}

.SFGL_color {
  color: var(--SFGL) !important;
}

.main_item_tit {
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
  margin-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 600;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (18.96 / 144));
  color: var(--chart_title);
}

.card_top {
  display: flex;
  justify-content: space-between;
  padding: 0 calc(var(--ROOTFONTFIZE) * (25 / 144));
}

.card_top span {
  display: flex;
  align-items: center;
}

.mr20 {
  margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.ml20 {
  margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.card_bottom {
  padding: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-bottom: 0 !important;
}

.card_history_top {
  display: flex;
  padding-left: calc(var(--ROOTFONTFIZE) * (25 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
  align-items: center;
}

.pl {
  padding-left: 0 !important;
}

.card_history_top div {
  color: rgba(255, 255, 255, 1);
}

.valuationCard {
  height: calc(var(--ROOTFONTFIZE) * (920 / 144));
}

.historymain {
  height: calc(var(--ROOTFONTFIZE) * (530 / 144));
  max-height: calc(var(--ROOTFONTFIZE) * (530 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  /* background-color: #1a1c21;
  border: 0.2px solid rgba(135, 135, 135, 0.3); */
  background-color: var(--charts_bgc);
  border: 1px solid var(--charts_brc);
  margin: 0 calc(var(--ROOTFONTFIZE) * (25 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (35 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.abslouteMain {
  height: calc(var(--ROOTFONTFIZE) * (365 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #1a1c21;
  border: 0.2px solid rgba(135, 135, 135, 0.3);
  margin: calc(var(--ROOTFONTFIZE) * (38 / 144)) calc(var(--ROOTFONTFIZE) * (45 / 144))
    calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.abslouteMainUp {
  padding-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.HistoricalValuation {
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.HistoricalValuationMain {
  padding-top: 0px !important;
}

.HistoricalValuation_info {
  padding-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144)) !important;
  padding-bottom: calc(var(--ROOTFONTFIZE) * (50 / 144)) !important;
  font-size: calc(var(--ROOTFONTFIZE) * (12 / 144));
  font-weight: 400;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (17.38 / 144));
  color: var(--stock_area_item_color2);
}

.scatterArea {
  display: flex;
  gap: calc(var(--ROOTFONTFIZE) * (30 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (20 / 144)) !important;
}

.scatterArea div:nth-child(1) {
  flex: 3;
  /* margin-right: calc(var(--ROOTFONTFIZE) * (30 / 144)); */
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: var(--charts_bgc);
  border: 0.2px solid var(--charts_brc);
}

.scatterArea div:nth-child(2) {
  flex: 7;
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: var(--charts_bgc);
  border: 0.2px solid var(--charts_brc);
}

.el-tabs {
  background-color: var(--bigger-card-bgc);
  /* padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144)); */
}

::v-deep .el-tabs__item {
  color: var(--el_tabs_item_color);
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
}

::v-deep .el-tabs__item:hover {
  color: var(--el_tabs_item_color_active_hover) !important;
}

::v-deep .el-tabs__item.is-active {
  color: var(--el_tabs_item_color_active) !important;
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
}

/*去掉tabs底部的下划线*/
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

/*去掉切换时el-tab-pane底部的蓝色下划线*/
::v-deep .el-tabs__active-bar {
  /* background-color: #1573fe !important; */
  background-color: var(--primary-color) !important;
}

/* el-table start */
::v-deep .table1 table {
  border: none;
}

::v-deep .table1 table .el-table__header-wrapper .has-gutter th {
  font-size: 14px !important;
}

::v-deep .el-table::before {
  background-color: var(--table_bgc) !important;
}

::v-deep .el-table__body .el-table__row .el-table__cell {
  background-color: var(--table_bgc);
  color: var(--table_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  height: calc(var(--ROOTFONTFIZE) * (60 / 144));
  border-bottom: 1px solid var(--table_brc);
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: var(--table_bgc_hover);
  color: var(--table_color_hover);
  cursor: pointer;
}

::v-deep .el-table {
  border-bottom: none !important;
  background-color: var(--table_bgc) !important;
  padding-bottom: 90px;
}

::v-deep .el-table__body-wrapper {
  height: auto !important;
  max-height: 100% !important;
  padding-bottom: 10px !important;
}

::v-deep .el-tab-pane {
  display: block;
  width: 100%;
  height: var(--TABPANEHEIGHT);
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.value_area {
  display: flex;
  align-items: center;
  justify-content: start;
}

.a {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #4ec0c5;
}

.b {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #4ec0c5;
}

.c {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #5387be;
}

.d {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #526cbf;
}

.e {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #5150bf;
}

.canvasArea {
  /* background-color: pink !important; */
}

.historymain2 {
  height: calc(var(--ROOTFONTFIZE) * (620 / 144));
}

.DDLINFO {
  margin-left: calc(var(--ROOTFONTFIZE) * (16 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (16 / 144));
  color: rgba(158, 158, 158, 1);
}

.main_item_bottom {
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
  text-align: right;
  padding-right: calc(var(--ROOTFONTFIZE) * (50 / 144));
  color: #5ae8ed;
}

.main_item_bottom:hover {
  cursor: pointer;
}

.summary_style {
  display: flex;
  color: var(--stock_area_item_color1);
}

.noVip {
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  letter-spacing: calc(var(--ROOTFONTFIZE) * (2 / 144));
  color: #78787a;
  color: #bababb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(var(--ROOTFONTFIZE) * (500 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: #373a42;
  margin: calc(var(--ROOTFONTFIZE) * (20 / 144)) calc(var(--ROOTFONTFIZE) * (30 / 144));
  border: 1px solid #333335;
}

.display_none {
  display: none;
}

.icon-taocan-suoxiao {
  font-size: calc(var(--ROOTFONTFIZE) * (50 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.echarts_area {
  position: relative;
  padding: 0 !important;
  overflow: hidden;
}

::v-deep .valutionanalysis_yield_ttm .cell {
  font-size: 14px !important;
}
</style>
