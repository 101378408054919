<template>
  <div class="container" id="rootchart" :style="cssVars">
    <div class="CARD">
      <!-- <div class="CARD_tit">qc鹏华深圳能源 REITs | 180401.SZ</div> -->
      <div class="CARD_tit">
        {{ STOCKNAME }} | <span class="CARD_tit_code">{{ CODE }}</span>
      </div>

      <div class="CARD_body">
        <CButtons
          @Cbtns="fnGetCbtns"
          class="CARD_body_tabs"
          :dButtons1="dButtons1"
          :defaultVal="dButtons1_current"
        />

        <div v-show="bButtons1_card1">
          <div class="choose_area">
            <CunknowButtons
              :default="start_date_chart1"
              class="CTimeButtons"
              @CunknowButtons_select_val="fnUnknowButtons_select_val_chart1"
            />
          </div>
          <div class="charts_area">
            <div class="choose_area_left AREA_STYLE">
              <div ref="chartColumn1" style="width: 100%; height: 350px"></div>
              <div class="info">数据截止日期：{{ DDL }}</div>
            </div>
            <div class="choose_area_right AREA_STYLE">
              <div class="InflowIntensity">
                <div class="InflowIntensity_title">
                  <span>近{{ day }}日主力净流入</span>
                  <span>单位（万元）</span>
                </div>
                <div class="InflowIntensity_body">
                  <div class="InflowIntensity_body_top">流入强度</div>
                  <div class="InflowIntensity_body_Lines">
                    <div
                      v-for="node in [1, 2, 3, 4, 5, 6]"
                      :key="node"
                      :class="node == AFTER_INDEX ? 'special' : ''"
                    ></div>
                  </div>
                </div>
              </div>
              <div
                class="InflowIntensity_body"
                ref="chartColumn2"
                style="width: 100%; height: 215px"
              ></div>
            </div>
          </div>

          <div class="charts_area">
            <div class="choose_area_left AREA_STYLE HEIGHT_262">
              <table>
                <tr>
                  <th></th>
                  <th>流入（万元）</th>
                  <th>流出（万元）</th>
                  <th>净流入（万元）</th>
                </tr>

                <tr class="rowArea odd">
                  <td>机构</td>

                  <td class="inflowStyle">
                    {{
                      parseInt(
                        Number(dFundFlowTableLeft_JG[0]) / 10000
                      ).toLocaleString()
                    }}
                  </td>
                  <td class="outflowStyle">
                    {{
                      parseInt(
                        Number(dFundFlowTableLeft_JG[1]) / 10000
                      ).toLocaleString()
                    }}
                  </td>
                  <td
                    :class="
                      dFundFlowTableLeft_JG[2] > 0
                        ? 'inflowStyle'
                        : 'outflowStyle'
                    "
                  >
                    <!-- <td class="inflowStyle"> -->
                    {{
                      parseInt(
                        Number(dFundFlowTableLeft_JG[2]) / 10000
                      ).toLocaleString()
                    }}
                  </td>
                </tr>

                <tr class="rowArea">
                  <td>大单</td>

                  <td class="inflowStyle">
                    {{
                      parseInt(
                        Number(dFundFlowTableLeft_DD[0]) / 10000
                      ).toLocaleString()
                    }}
                  </td>
                  <td class="outflowStyle">
                    {{
                      parseInt(
                        Number(dFundFlowTableLeft_DD[1]) / 10000
                      ).toLocaleString()
                    }}
                  </td>
                  <td
                    :class="
                      dFundFlowTableLeft_DD[2] > 0
                        ? 'inflowStyle'
                        : 'outflowStyle'
                    "
                  >
                    {{
                      parseInt(
                        Number(dFundFlowTableLeft_DD[2]) / 10000
                      ).toLocaleString()
                    }}
                  </td>
                </tr>

                <tr class="rowArea odd">
                  <td>中单</td>

                  <td class="inflowStyle">
                    {{
                      parseInt(
                        Number(dFundFlowTableLeft_ZD[0]) / 10000
                      ).toLocaleString()
                    }}
                  </td>
                  <td class="outflowStyle">
                    {{
                      parseInt(
                        Number(dFundFlowTableLeft_ZD[1]) / 10000
                      ).toLocaleString()
                    }}
                  </td>
                  <td
                    :class="
                      dFundFlowTableLeft_ZD[2] > 0
                        ? 'inflowStyle'
                        : 'outflowStyle'
                    "
                  >
                    {{
                      parseInt(
                        Number(dFundFlowTableLeft_ZD[2]) / 10000
                      ).toLocaleString()
                    }}
                  </td>
                </tr>

                <tr class="rowArea">
                  <td>小单</td>

                  <td class="inflowStyle">
                    {{
                      parseInt(
                        Number(dFundFlowTableLeft_XD[0]) / 10000
                      ).toLocaleString()
                    }}
                  </td>
                  <td class="outflowStyle">
                    {{
                      parseInt(
                        Number(dFundFlowTableLeft_XD[1]) / 10000
                      ).toLocaleString()
                    }}
                  </td>
                  <td
                    :class="
                      dFundFlowTableLeft_XD[2] > 0
                        ? 'inflowStyle'
                        : 'outflowStyle'
                    "
                  >
                    {{
                      parseInt(
                        Number(dFundFlowTableLeft_XD[2]) / 10000
                      ).toLocaleString()
                    }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="choose_area_right AREA_STYLE HEIGHT_262">
              <table>
                <tr>
                  <th>天数</th>
                  <th>净流天</th>
                  <th>净流额（万元）</th>
                  <th>净流率</th>
                </tr>
                <tr class="rowArea odd">
                  <td>5</td>
                  <td
                    :class="
                      dFundFlowTableRight_ONE[0] > 0
                        ? 'inflowStyle'
                        : 'outflowStyle'
                    "
                  >
                    {{ dFundFlowTableRight_ONE[0] }}
                  </td>
                  <td
                    :class="
                      dFundFlowTableRight_ONE[1] > 0
                        ? 'inflowStyle'
                        : 'outflowStyle'
                    "
                  >
                    {{
                      parseInt(
                        Number(dFundFlowTableRight_ONE[1]) / 10000
                      ).toLocaleString()
                    }}
                  </td>

                  <td
                    :class="
                      dFundFlowTableRight_ONE[2] > 0
                        ? 'inflowStyle'
                        : 'outflowStyle'
                    "
                  >
                    {{ Number(dFundFlowTableRight_ONE[2]).toFixed(3) + "%" }}
                  </td>
                </tr>

                <tr class="rowArea">
                  <td>10</td>
                  <td
                    :class="
                      dFundFlowTableRight_TWO[0] > 0
                        ? 'inflowStyle'
                        : 'outflowStyle'
                    "
                  >
                    {{ dFundFlowTableRight_TWO[0] }}
                  </td>
                  <td
                    :class="
                      dFundFlowTableRight_TWO[1] > 0
                        ? 'inflowStyle'
                        : 'outflowStyle'
                    "
                  >
                    {{
                      parseInt(
                        Number(dFundFlowTableRight_TWO[1]) / 10000
                      ).toLocaleString()
                    }}
                  </td>

                  <td
                    :class="
                      dFundFlowTableRight_TWO[2] > 0
                        ? 'inflowStyle'
                        : 'outflowStyle'
                    "
                  >
                    {{ Number(dFundFlowTableRight_TWO[2]).toFixed(3) + "%" }}
                  </td>
                </tr>

                <tr class="rowArea odd">
                  <td>20</td>
                  <td
                    :class="
                      dFundFlowTableRight_THREE[0] > 0
                        ? 'inflowStyle'
                        : 'outflowStyle'
                    "
                  >
                    {{ dFundFlowTableRight_THREE[0] }}
                  </td>
                  <td
                    :class="
                      dFundFlowTableRight_THREE[1] > 0
                        ? 'inflowStyle'
                        : 'outflowStyle'
                    "
                  >
                    {{
                      parseInt(
                        Number(dFundFlowTableRight_THREE[1]) / 10000
                      ).toLocaleString()
                    }}
                  </td>

                  <td
                    :class="
                      dFundFlowTableRight_THREE[2] > 0
                        ? 'inflowStyle'
                        : 'outflowStyle'
                    "
                  >
                    {{ Number(dFundFlowTableRight_THREE[2]).toFixed(3) + "%" }}
                  </td>
                </tr>

                <tr class="rowArea">
                  <td>60</td>
                  <td
                    :class="
                      dFundFlowTableRight_FOUR[0] > 0
                        ? 'inflowStyle'
                        : 'outflowStyle'
                    "
                  >
                    {{ dFundFlowTableRight_FOUR[0] }}
                  </td>
                  <td
                    :class="
                      dFundFlowTableRight_FOUR[1] > 0
                        ? 'inflowStyle'
                        : 'outflowStyle'
                    "
                  >
                    {{
                      parseInt(
                        Number(dFundFlowTableRight_FOUR[1]) / 10000
                      ).toLocaleString()
                    }}
                  </td>
                  <td
                    :class="
                      dFundFlowTableRight_FOUR[2] > 0
                        ? 'inflowStyle'
                        : 'outflowStyle'
                    "
                  >
                    {{ Number(dFundFlowTableRight_FOUR[2]).toFixed(3) + "%" }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div v-show="bButtons1_card2">
          <div class="choose_area">
            <CTimeButtons
              :default="start_date_chart3"
              @CTimeButtons_select_val="fnCTimeButtons_select_val_chart3"
              class="CTimeButtons"
            />

            <div class="downloadBtns">
              <CButtonsDownload
                :dButtonsDownloads="dButtonsDownloads1"
                @fnButtondownload="fnButtonDL8_5"
              />

              <span style="display: none">
                <download-excel
                  id="downloadExcel1"
                  :data="picData"
                  :fields="picData_json"
                  header="历史资金构成"
                  name="历史资金构成.xls"
                >
                </download-excel>
              </span>
            </div>
          </div>

          <div ref="chart8_5" class="charts_area HEIGHT_500 AREA_STYLE">
            <span
              @click="fnOpenDialog"
              class="iconfont icon-fangda icon-fangda2"
            ></span>

            <div
              v-loading="loading_chart4"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="canvasArea"
              id="chartColumn3"
              ref="chartColumn3"
              style="width: 100%; height: 480px"
            ></div>
          </div>

          <div class="choose_area">
            <CButtonsSeparate
              @Cbtns_sep="fnCbtns_sep_chart3"
              class="Cbtns_sep_chart3_style"
              :dButtons1="dButtons_separrate_chart3"
              :dButtons1_current="dButtons_separrate_current_chart3"
            />

            <div class="downloadBtns">
              <CButtonsDownload
                :dButtonsDownloads="dButtonsDownloads1"
                @fnButtondownload="fnButtonDL8_6"
              />

              <!-- <span style="display: none;">
             <download-excel
          id="downloadExcel1"
          :data="dDiffTimeReturn"
          :fields="json_fields1"
          header="板块收益不同时间段对比"
          name="板块收益不同时间段对比.xls"
        >
        </download-excel>
          </span>
           -->
            </div>
          </div>

          <div class="charts_area HEIGHT_400 AREA_STYLE" ref="chart8_6">
            <span
              @click="fnOpenDialog2"
              class="iconfont icon-fangda icon-fangda2"
            ></span>

            <div
              v-loading="loading_chart4"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="canvasArea"
              id="chartColumn4"
              ref="chartColumn4"
              style="width: 100%; height: 370px"
            ></div>
          </div>
        </div>

        <div v-show="bButtons1_card3">
          <div class="chart5ChooseArea">
            <span>
              <span>占比选项</span>
              <CSelect
                @CSelect_select_val="fnCSelect_chart5"
                :multiple="false"
                :auto="true"
                :clearable="false"
                :default="select_val_Proportion"
                :options="options_Proportion"
              >
              </CSelect>
            </span>

            <span>
              <div class="downloadBtns">
                <CButtonsDownload
                  :dButtonsDownloads="dButtonsDownloads1"
                  @fnButtondownload="fnButtonDL8_7"
                />

                <span style="display: none">
                  <download-excel
                    id="downloadExcel8_7"
                    :data="json_fields_charts_data"
                    :fields="json_fields_charts"
                    header="大宗交易情况"
                    name="大宗交易情况.xls"
                  >
                  </download-excel>
                </span>

                <span style="display: none">
                  <download-excel
                    id="downloadExcel8_71"
                    :data="json_fields_ipo_data"
                    :fields="json_fields_ipo"
                    header="大宗交易情况_IPO"
                    name="大宗交易情况_IPO.xls"
                  >
                  </download-excel>
                </span>
              </div>
            </span>
          </div>

          <div class="pr">
            <span
              @click="fnOpenDialog3"
              class="iconfont icon-fangda icon-fangda2"
            ></span>

            <div
              v-loading="loading_chart5"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="canvasArea CHARTS_BGC"
              id="chartColumn5"
              ref="chartColumn5"
              style="width: 100%; height: 642px"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div
            v-loading="loading_chart4"
            element-loading-text="数据量巨大，正在计算中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            class="canvasArea"
            id="chartColumn3"
            ref="chartColumn3"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible2"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible2"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown2"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div
            v-loading="loading_chart4"
            element-loading-text="数据量巨大，正在计算中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            class="canvasArea"
            id="chartColumn4"
            ref="chartColumn4"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible3"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible3"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown3"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div
            v-loading="loading_chart5"
            element-loading-text="数据量巨大，正在计算中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            class="canvasArea CHARTS_BGC"
            id="chartColumn5"
            ref="chartColumn5"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </el-dialog>
    </CDialog>

    <!-- <div class="CARD" v-show="true">
           
        </div> -->
  </div>
</template>

<script>
import CSelects from "@/components/Basic/CSelects";
import CSelect from "@/components/Basic/CSelect";
import CCategoryRadio from "@/components/Basic/CCategoryRadio";
import CButtons from "@/components/Basic/CButtons";
import CTimeButtons from "@/components/Basic/CTimeButtons";
import CButtonsSeparate from "@/components/Basic/CButtons_separate";
import CButtonsDownload from "@/components/Basic/CButtons_download";
import CunknowButtons from "@/components/Basic/CunknowButtons";
import CDialog from "@/components/Basic/CDialog";

import html2canvas from "html2canvas";

import { fnComputeTime, fnGetMuchDecimals, fnDownloadBgc } from "@/utils/util";

export default {
  props: {
    isFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // CODE: window.sessionStorage.getItem("CURRENTCODE"),
      // STOCKNAME: window.sessionStorage.getItem("CURRENTNAME"),
      sCurrentTheme: this.$store.state.theme == "dark" ? true : false,
      ROOTFONTFIZE: 0,

      loading_chart4: false,
      loading_chart5: false,

      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      width_dialog: "80%",
      height_dialog: "73vh",
      height_chart: "71vh",
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: "5vh",

      DDL: "",

      CODE: "",
      STOCKNAME: "",

      options_Proportion: [
        {
          label: "成交量",
          value: "vol",
        },
        {
          label: "成交额",
          value: "amt",
        },
      ],
      select_val_Proportion: "vol",

      //================ new =================================
      dButtons1: ["资金流入情况", "成交额及换手率对比", "大宗交易情况"],
      dButtons1_current: "资金流入情况",

      bButtons1_card1: true,
      bButtons1_card2: false,
      bButtons1_card3: false,

      chartColumn1: null,
      dFundFlowPie: [],

      AFTER_INDEX: 3,
      SPECColor: "rgba(255, 179, 179, 1)",

      chartColumn2: null,
      dFundFlowTableBar: [],

      dFundFlowTableLeft_ZD: [],
      dFundFlowTableLeft_DD: [],
      dFundFlowTableLeft_XD: [],
      dFundFlowTableLeft_JG: [],

      dFundFlowTableRight_ONE: [],
      dFundFlowTableRight_TWO: [],
      dFundFlowTableRight_THREE: [],
      dFundFlowTableRight_FOUR: [],

      // 成交额及换手率对比
      dDailyAmtTurnoverReit: [],
      picData: [],
      picData_json: {},
      chartColumn3: null,
      chartColumn4: null,

      chartColumn5: null,
      chart5_data: null,

      start_date_chart1: "1D",
      start_date_chart3: "YTD",
      dButtons_separrate_chart3: ["月", "年"],
      dButtons_separrate_current_chart3: "年",

      day: null,

      dButtons2: ["下载图片"],

      // 下载start
      json_fields_charts: {},
      json_fields_charts_data: [],

      json_fields_ipo: {},
      json_fields_ipo_data: [],

      dButtonsDownloads1: [
        {
          id: "241069",
          name: "下载图片",
        },
        {
          id: "241070",
          name: "下载表格",
        },
      ],

      // 下载end
    };
  },
  created() {
    //  this.fnGetDailyAmtTurnoverReit()
    //   if(this.isFlag){
    //     this.fnGetCbtns("资金流入情况");
    //  }
  },
  mounted() {
    window.addEventListener("resize", this.funResize);
    this.fnGetWidth();
  }, 
  beforeDestroy(){
     window.removeEventListener("resize", this.funResize);
     this.chartColumn1&&this.chartColumn1.dispose()
    this.chartColumn2&&this.chartColumn2.dispose()
    this.chartColumn3&&this.chartColumn3.dispose()
    this.chartColumn4&&this.chartColumn4.dispose()
    this.chartColumn5&&this.chartColumn5.dispose()
  },
  methods: {
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == "dark" ? true : false;
    },
    funResize() {
      this.fnGetWidth();
      if (this.chartColumn1) {
        this.chartColumn1.resize();
      }
      if (this.chartColumn2) {
        this.chartColumn2.resize();
      }
      if (this.chartColumn3) {
        this.chartColumn3.resize();
      }
      if (this.chartColumn4) {
        this.chartColumn4.resize();
      }
      if (this.chartColumn5) {
        this.chartColumn5.resize();
      }
    },

    fnGetWidth() {
      this.ROOTFONTFIZE = localStorage.getItem("ROOTFONTFIZE");
    },

    // 放大 start
    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.initChart3(true);
      });
    },

    fnDropdown(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart3(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart3(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart3(true);
        });
      }
    },

    fnOpenDialog2() {
      this.dialogVisible2 = true;
      this.$nextTick(() => {
        this.initChart4(true);
      });
    },

    fnDropdown2(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      }
    },

    fnOpenDialog3() {
      this.dialogVisible3 = true;
      this.$nextTick(() => {
        this.initChart5(true);
      });
    },

    fnDropdown3(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart5(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart5(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart5(true);
        });
      }
    },

    // 放大 end

    // 下载 start
    fnButtonDL8_5(val) {
      if (val == "下载图片") {
        this.FnDownload8_5();
      } else {
        document.getElementById("downloadExcel1").click();
      }
    },
    FnDownload8_5() {
      let h = this.$refs.chart8_5.scrollHeight;
      let w = this.$refs.chart8_5.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chart8_5, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "成交额与换手率走势"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    fnButtonDL8_6(val) {
      if (val == "下载图片") {
        this.FnDownload8_6();
      } else {
        document.getElementById("downloadExcel1").click();
      }
    },
    FnDownload8_6() {
      let h = this.$refs.chart8_6.scrollHeight;
      let w = this.$refs.chart8_6.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chart8_6, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download =
          this.dButtons_separrate_current_chart3 == "月"
            ? "成交额与换手率按月走势"
            : "成交额与换手率按年走势"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    fnButtonDL8_7(val) {
      if (val == "下载图片") {
        this.FnDownload8_7();
      } else {
        document.getElementById("downloadExcel8_7").click();
        document.getElementById("downloadExcel8_71").click();
      }
    },

    FnDownload8_7() {
      let h = this.$refs.chartColumn5.scrollHeight;
      let w = this.$refs.chartColumn5.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn5, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "大宗交易情况"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    // 下载 end

    // =====================================================================================================
    fnCTimeButtons_select_val_chart3(val) {
      this.start_date_chart3 = val;
      this.fnGetDailyAmtTurnoverReit();
    },
    fnCbtns_sep_chart3(val) {
      this.dButtons_separrate_current_chart3 = val;
      this.fnGetDailyAmtTurnoverReit();
    },
    // 1. 成交额及换手率对比 数据
    async fnGetDailyAmtTurnoverReit() {
      this.loading_chart4 = true;
      let code = this.CODE;
      let start_date = this.start_date_chart3;
      let freq = this.dButtons_separrate_current_chart3 == "年" ? "Y" : "M";

      const { data } = await this.$https.get(
        "/api/daily_amt_turnover_reit?code=" +
          code +
          "&start_date=" +
          start_date +
          "&freq=" +
          freq
      );
      if (data.code !== 200) return this.$message.error(data.msg);

      this.dDailyAmtTurnoverReit = data.data;

      // 下载 start
      this.picData = JSON.parse(JSON.stringify(data.data.pic));
      // 判断VIP 普通 start
      let levelName = window.sessionStorage.getItem("levelName");
      // 判断VIP6个月 普通3个月 end
      if (levelName == "普通客户") {
        let result = fnComputeTime("3M");
        this.picData = this.picData.filter((node) => {
          return node["tr_date"] >= result;
        });
      } else {
        let result = fnComputeTime("6M");
        this.picData = this.picData.filter((node) => {
          return node["tr_date"] >= result;
        });
      }
      // 判断VIP 普通 end

      this.picData_json = {
        日期: "tr_date",
        成交额: "成交额",
        换手率: "换手率",
      };
      // 下载 end
      this.$nextTick(() => {
        this.initChart3();
      });
      this.$nextTick(() => {
        this.initChart4();
      });
    },
    initChart3(val) {
      if (!val) {
        this.chartColumn3 = this.$echarts.init(
          document.querySelectorAll("#chartColumn3")[0]
        );
      } else {
        this.chartColumn3 = this.$echarts.init(
          document.querySelectorAll("#chartColumn3")[1]
        );
      }

      // this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);
      this.chartColumn3.clear();

      let dDailyAmtTurnoverReit = this.dDailyAmtTurnoverReit;
      //
      let X = dDailyAmtTurnoverReit.pic.map((node) => {
        return node.tr_date;
      });
      // let CJE
      let CJE = dDailyAmtTurnoverReit.pic.map((node) => {
        return node.成交额;
      });
      // let HSL
      let HSL = dDailyAmtTurnoverReit.pic.map((node) => {
        return node.换手率;
      });

      // 平均
      let avg_rate = dDailyAmtTurnoverReit.avg.换手率;
      let avg_volume = (
        Number(dDailyAmtTurnoverReit.avg.成交额) / 10000
      ).toFixed(0);

      let texta = this.STOCKNAME;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        title: {
          // 标题设置
          text: texta + " 成交额及换手率走势", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (16 / 144),
          },
          left: "center",
          top: 0,
        },
        grid: {
          top: this.ROOTFONTFIZE * (100 / 144),
          bottom: this.ROOTFONTFIZE * (30 / 144),
          right: this.ROOTFONTFIZE * (50 / 144),
          left: this.ROOTFONTFIZE * (30 / 144),
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node, idx) => {
              let val = "";
              if (idx == 0) {
                val = (Number(node.value) / 10000).toFixed(1);
                str += `${node.marker}${node.seriesName}：${val}`;
              } else {
                val = Number(node.value).toFixed(2);
                str += `${node.marker}${node.seriesName}：${val}%`;
              }
              str += "<br>";

              return str;
            });
            return str;
          },
        },

        legend: [
          {
            show: true,
            itemWidth: this.ROOTFONTFIZE * (20 / 144),
            itemHeight: this.ROOTFONTFIZE * (10 / 144),
            data: [
              {
                name: "成交额（左轴）",
                icon: "roundRect",
                color: "rgba(86, 84, 202, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            left: this.ROOTFONTFIZE * (30 / 144),
            y: this.ROOTFONTFIZE * (30 / 144),
          },

          {
            show: true,
            itemWidth: this.ROOTFONTFIZE * (20 / 144),
            itemHeight: this.ROOTFONTFIZE * (10 / 144),
            data: [
              {
                name: "换手率（右轴）",
                icon: "roundRect",
                color: "rgba(56, 56, 118, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            left: this.ROOTFONTFIZE * (160 / 144),
            y: this.ROOTFONTFIZE * (30 / 144),
          },
        ],

        xAxis: {
          type: "category",
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            margin: this.ROOTFONTFIZE * (20 / 144),
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
        },
        yAxis: [
          {
            name: "单位：（万元）",
            nameTextStyle: {
              color: OPTIONDATA.yAxis.nameTextStyle.color,
              fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
            },
            nameGap: OPTIONDATA.yAxis.nameGap,
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            position: "left",
            axisLabel: {
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (args == 0) {
                  return 0;
                } else {
                  let num = (parseInt(Math.abs(args)) + "").length >= 2 ? 1 : 2;
                  return (Number(args) / 10000).toFixed(num);
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            // type: 'value',
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            position: "right",
            axisLabel: {
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (args == 0) {
                  return 0;
                } else {
                  return args + "%";
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "成交额（左轴）",
            data: CJE,
            yAxisIndex: 0,
            type: "line",
            itemStyle: {
              color: "rgba(89, 143, 200, 1)",
            },
            areaStyle: {
              color: "rgba(89, 143, 200, 1)",
            },
            symbol: "none",

            // itemStyle : { normal: {label : {show: true}}}
          },
          {
            name: "换手率（右轴）",
            data: HSL,
            yAxisIndex: 1,
            type: "line",

            itemStyle: {
              color: "rgba(77, 168, 164, 1)",
            },
            symbol: "none",
          },
          {
            name: "平均值",
            yAxisIndex: 1,
            // data:  dValuationAnalysisSingle_pic1_lines.line.avg,
            type: "line",
            itemStyle: {
              color: "rgba(255, 255, 255, 1)",
            },
            markLine: {
              symbol: ["none", "none"],

              data: [
                {
                  yAxis: avg_rate,
                },
              ],

              lineStyle: {
                type: "dashed",
                color: "rgba(255, 0, 0, 1)",
                width: 2,
              },

              label: {
                show: true,
                position: "insideEndTop",
                fontSize: this.ROOTFONTFIZE * (16 / 144),
                formatter: function (args) {
                  let val = args.value * 100;
                  val = val.toFixed(0);
                  val = val + "bps";
                  return `{title|平均成交额}  {value|${avg_volume}万}

                              {title|平均换手率}  {value|${avg_rate.toFixed(
                                2
                              )}%} `;
                },
                rich: {
                  title: {
                    color: OPTIONDATA.xAxis.axisLabel.color,
                    fontSize: this.ROOTFONTFIZE * (16 / 144),
                  },
                  value: {
                    color: "rgba(255, 0, 0, 1)",
                    fontSize: this.ROOTFONTFIZE * (16 / 144),
                    fontWeight: "bolder",
                  },
                },
              },
            },
          },
        ],
      };

      this.chartColumn3.setOption(option);
      this.chartColumn3.resize();
      // this.funResize()
    },

    initChart4(val) {
      if (!val) {
        this.chartColumn4 = this.$echarts.init(
          document.querySelectorAll("#chartColumn4")[0]
        );
      } else {
        this.chartColumn4 = this.$echarts.init(
          document.querySelectorAll("#chartColumn4")[1]
        );
      }

      // this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4);
      this.chartColumn4.clear();
      let dDailyAmtTurnoverReit = this.dDailyAmtTurnoverReit;
      //
      let X = dDailyAmtTurnoverReit.bar.map((node) => {
        return node.tr_date;
      });
      // let CJE
      let CJE = dDailyAmtTurnoverReit.bar.map((node) => {
        return Number(node.成交额) / 10000;
      });
      // let HSL
      let HSL = dDailyAmtTurnoverReit.bar.map((node) => {
        return node.换手率;
      });

      let texta = this.STOCKNAME;

      let dButtons_separrate_current_chart3 =
        this.dButtons_separrate_current_chart3;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        title: {
          // 标题设置
          text:
            texta +
            " 成交额及换手率 按" +
            dButtons_separrate_current_chart3 +
            "走势", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (16 / 144),
          },
          left: "center",
          top: 0,
        },
        grid: {
          top: this.ROOTFONTFIZE * (100 / 144),
          bottom: this.ROOTFONTFIZE * (10 / 144),
          right: this.ROOTFONTFIZE * (50 / 144),
          left: this.ROOTFONTFIZE * (30 / 144),
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node, idx) => {
              let val = "";
              if (idx == 0) {
                val = (Number(node.value) / 10000).toFixed(1);
                str += `${node.marker}${node.seriesName}：${val}`;
              } else {
                val = Number(node.value).toFixed(2);
                str += `${node.marker}${node.seriesName}：${val}%`;
              }
              str += "<br>";

              return str;
            });
            return str;
          },
        },

        legend: [
          {
            show: true,
            itemWidth: this.ROOTFONTFIZE * (20 / 144),
            itemHeight: this.ROOTFONTFIZE * (10 / 144),
            data: [
              {
                name: "成交额",
                icon: "roundRect",
                color: "rgba(86, 84, 202, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            left: this.ROOTFONTFIZE * (30 / 144),
            y: this.ROOTFONTFIZE * (30 / 144),
          },

          {
            show: true,
            itemWidth: this.ROOTFONTFIZE * (20 / 144),
            itemHeight: this.ROOTFONTFIZE * (10 / 144),
            data: [
              {
                name: "换手率",
                icon: "roundRect",
                color: "rgba(56, 56, 118, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            left: this.ROOTFONTFIZE * (150 / 144),
            y: this.ROOTFONTFIZE * (30 / 144),
          },
        ],

        xAxis: {
          type: "category",
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            margin: this.ROOTFONTFIZE * (20 / 144),
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
        },
        yAxis: [
          {
            name: "单位：（亿元）",
            nameTextStyle: {
              color: OPTIONDATA.yAxis.nameTextStyle.color,
              fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
            },
            nameGap: OPTIONDATA.yAxis.nameGap,
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            position: "left",
            axisLabel: {
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (args == 0) {
                  return 0;
                } else {
                  let val = Number(args) / 10000;
                  let num = (parseInt(Math.abs(val)) + "").length >= 2 ? 1 : 2;
                  return Number(val).toFixed(num);
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            // type: 'value',
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            position: "right",
            axisLabel: {
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (args == 0) {
                  return 0;
                } else {
                  return args + "%";
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "成交额",
            type: "bar",
            barWidth: "38px",
            yAxisIndex: 0,
            itemStyle: {
              color: "rgba(90, 202, 198, 1)",
            },
            data: CJE,
          },
          {
            name: "换手率",
            type: "line",
            symbol: "none",
            yAxisIndex: 1,
            itemStyle: {
              color: "rgba(58, 77, 198, 1)",
            },
            lineStyle: {
              width: 5,
            },
            data: HSL,
          },
        ],
      };

      this.chartColumn4.setOption(option);
      this.chartColumn4.resize();
      this.loading_chart4 = false;
      // this.funResize()
    },

    // 2. 资金流入情况 数据
    fnUnknowButtons_select_val_chart1(val) {
      this.start_date_chart1 = val;
      this.fnGetFundflowPieTableReit();
    },

    async fnGetFundflowPieTableReit() {
      let flow_type = "normal";
      let code = this.CODE;
      let start_date = this.start_date_chart1 + "";
      start_date = start_date.slice(0, -1);

      this.day = start_date;

      const { data } = await this.$https.get(
        "/api/fundflow_pie_table_reit?code=" +
          code +
          "&start_date=" +
          start_date +
          "&flow_type=" +
          flow_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 设置DDL
      this.DDL = data.data.last_date;

      // 1. 饼图
      this.dFundFlowPie = data.data.pie;
      // 完成

      // 2. 线段
      let condition = data.data.condition;

      let num = Number(condition) / (1 / 6);
      this.AFTER_INDEX = Math.ceil(num);
      // 完成

      // 3. 柱状图
      this.dFundFlowTableBar = data.data.bar;

      // 4. 表1
      let dataTmp = data.data.table.data;
      this.fnSetTable1(dataTmp);

      // 5. 表2
      let dataTmp6 = data.data.table2.data;
      this.fnSetTable2(dataTmp6);

      this.fnSetSPEColor();
      // 完成
      this.$nextTick(() => {
        this.initChart1();
        this.initChart2();
      });
    },

    // 三角形颜色
    fnSetSPEColor() {
      let arr = this.$store.state.SPEColor;
      this.SPECColor = arr[this.AFTER_INDEX - 1];
    },

    initChart1() {
      this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
      this.chartColumn1.clear();

      let dFundFlowPie = this.dFundFlowPie;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "middle",
          right: this.ROOTFONTFIZE * (20 / 144),
          itemGap: this.ROOTFONTFIZE * (18 / 144),
          itemWidth: this.ROOTFONTFIZE * (16 / 144),
          itemHeight: this.ROOTFONTFIZE * (16 / 144),
          orient: "vertical",
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },
        },
        color: [
          "#870000",
          "#ae0101",
          "#f53434",
          "#ff8181",
          "#77ea7a",
          "#27b429",
          "#0c7e0c",
          "#053d05",
        ],
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["50%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
              color: "#fff",
              // color: OPTIONDATA.legend.textStyle.color,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: this.ROOTFONTFIZE * (40 / 144),
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: dFundFlowPie["机构流入"],
                name: "机构流入",
                label: {
                  normal: {
                    show: true,
                    position: "inner",
                    formatter: "{d}%",
                  },
                },
              },
              {
                value: dFundFlowPie["大单流入"],
                name: "大单流入",
                label: {
                  normal: {
                    show: true,
                    position: "inner",
                    formatter: "{d}%",
                  },
                },
              },

              {
                value: dFundFlowPie["中单流入"],
                name: "中单流入",
                label: {
                  normal: {
                    show: true,
                    position: "inner",
                    formatter: "{d}%",
                  },
                },
              },

              {
                value: dFundFlowPie["小单流入"],
                name: "小单流入",
                label: {
                  normal: {
                    show: true,
                    position: "inner",
                    formatter: "{d}%",
                  },
                },
              },

              {
                value: dFundFlowPie["小单流出"],
                name: "小单流出",
                label: {
                  normal: {
                    show: true,
                    position: "inner",
                    formatter: "{d}%",
                  },
                },
              },

              {
                value: dFundFlowPie["中单流出"],
                name: "中单流出",
                label: {
                  normal: {
                    show: true,
                    position: "inner",
                    formatter: "{d}%",
                  },
                },
              },

              {
                value: dFundFlowPie["大单流出"],
                name: "大单流出",
                label: {
                  normal: {
                    show: true,
                    position: "inner",
                    formatter: "{d}%",
                  },
                },
              },

              {
                value: dFundFlowPie["机构流出"],
                name: "机构流出",
                label: {
                  normal: {
                    show: true,
                    position: "inner",
                    formatter: "{d}%",
                  },
                },
              },
            ],
          },
        ],
      };

      this.chartColumn1.setOption(option);
      this.chartColumn1.resize();

      // this.funResize()
    },
    initChart2() {
      this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2);
      this.chartColumn2.clear();
      let data = this.dFundFlowTableBar;
      let X = data.map((node) => {
        return node.tr_date;
      });

      let Y = data.map((node) => {
        return node.data;
      });

      X = X.map((node) => {
        let arr1 = node.split("-");
        arr1.shift();
        let str2 = arr1.join("-");
        return str2;
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        grid: {
          //方法 2
          left: this.ROOTFONTFIZE * (50 / 144),
          right: this.ROOTFONTFIZE * (50 / 144),
          bottom: this.ROOTFONTFIZE * (80 / 144),
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: X,
          axisLine: {
            show: false,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            show: true,
            margin: this.ROOTFONTFIZE * (20 / 144),
            showMaxLabel: true,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          scale: true,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (12 / 144),
            show: false,
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            type: "bar",
            data: Y.map((node) => {
              return {
                value: node,
                label: {
                  show: true,
                  position: node > 0 ? "top" : "bottom",
                  fontSize: this.ROOTFONTFIZE * (14 / 144),
                  formatter: function (value) {
                    let val = value.value;
                    val = Number(val) / 10000;

                    if (val > 10 && val < 100) {
                      val = Number(val).toFixed(1);
                      return val;
                    }

                    if (Number(val) > 100) {
                      val = parseInt(Number(val));
                      return val;
                    }

                    if (Number(val) < 10 && Number(val) > 0) {
                      val = Number(val).toFixed(2);
                      return val;
                    }

                    if (Number(val) < 0 && Number(val) > -10) {
                      val = Number(val).toFixed(2);
                      return val;
                    }

                    if (Number(val) < -10 && Number(val) > -100) {
                      val = Number(val).toFixed(1);
                      return val;
                    }

                    if (Number(val) < -100) {
                      val = parseInt(Number(val));
                      return val;
                    }
                  },
                  color:
                    node > 0 ? "rgba(250, 40, 50, 1)" : "rgba(22, 188, 80, 1)",
                  fontSize: this.ROOTFONTFIZE * (12 / 144),
                },
              };
            }),
            // barWidth: "30px",
            markLine: {
              symbol: ["none", "none"], // none为标线两端的样式为无，可更改
              data: [
                {
                  yAxis: 0,
                },
              ],
              label: {
                show: false,
              },
              lineStyle: {
                type: "solid",
                color: "#6e7079",
              },
            },

            itemStyle: {
              normal: {
                color: function (val) {
                  let value = val.value;
                  if (value > 0) {
                    return "rgba(250, 40, 50, 1)";
                  } else {
                    return "rgba(22, 188, 80, 1)";
                  }
                },
              },
            },
          },
        ],
      };

      this.chartColumn2.setOption(option);
      this.chartColumn2.resize();
      // this.funResize()
    },

    fnSetTable1(dataTmp) {
      let dataTmp2 = dataTmp[0];
      let dataTmp3 = dataTmp[1];
      let dataTmp4 = dataTmp[2];
      let dataTmp5 = dataTmp[3];

      this.dFundFlowTableLeft_ZD = dataTmp2;
      this.dFundFlowTableLeft_DD = dataTmp3;
      this.dFundFlowTableLeft_XD = dataTmp4;
      this.dFundFlowTableLeft_JG = dataTmp5;
    },
    fnSetTable2(dataTmp6) {
      let dataTmp7 = dataTmp6[0];
      let dataTmp8 = dataTmp6[1];
      let dataTmp9 = dataTmp6[2];
      let dataTmp10 = dataTmp6[3];
      this.dFundFlowTableRight_ONE = dataTmp7;
      this.dFundFlowTableRight_TWO = dataTmp8;

      this.dFundFlowTableRight_THREE = dataTmp9;

      this.dFundFlowTableRight_FOUR = dataTmp10;
    },

    // 3. 大宗交易情况 数据
    fnCSelect_chart5(val) {
      this.select_val_Proportion = val;
      this.fnGetBlockTradeReit();
    },

    async fnGetBlockTradeReit() {
      this.loading_chart5 = true;
      let code = this.CODE;
      let start_date = "max";
      let factor_ = this.select_val_Proportion;

      const { data } = await this.$https.get(
        "/api/block_trade_reit?code=" +
          code +
          "&start_date=" +
          start_date +
          "&factor_=" +
          factor_
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 下载 start
      this.json_fields_charts = {};
      this.json_fields_ipo = {};
      this.json_fields_charts_data = [];
      this.json_fields_ipo_data = [];

      this.json_fields_charts_data = JSON.parse(
        JSON.stringify(data.data.charts)
      );
      // 判断VIP 普通 start
      let levelName = window.sessionStorage.getItem("levelName");
      // 判断VIP6个月 普通3个月 end
      if (levelName == "普通客户") {
        let result = fnComputeTime("3M");
        this.json_fields_charts_data = this.json_fields_charts_data.filter(
          (node) => {
            return node["tr_date"] >= result;
          }
        );
      } else {
        let result = fnComputeTime("6M");
        this.json_fields_charts_data = this.json_fields_charts_data.filter(
          (node) => {
            return node["tr_date"] >= result;
          }
        );
      }
      // 判断VIP 普通 end

      Object.keys(this.json_fields_charts_data[0]).forEach((node) => {
        if (node == "tr_date") {
          this.json_fields_charts["日期"] = node;
        } else {
          this.json_fields_charts[node] = node;
        }
      });

      this.json_fields_ipo_data = JSON.parse(
        JSON.stringify(data.data.ipo_date)
      );
      Object.keys(this.json_fields_ipo_data[0]).forEach((node) => {
        if (node == "IPO_date") {
          this.json_fields_ipo["日期"] = node;
        } else {
          this.json_fields_ipo["类型"] = node;
        }
      });

      // 下载 end

      let DATA = data.data;

      this.chart5_data = DATA;
      this.$nextTick(() => {
        this.initChart5();
      });
    },

    initChart5(val) {
      if (!val) {
        this.chartColumn5 = this.$echarts.init(
          document.querySelectorAll("#chartColumn5")[0]
        );
      } else {
        this.chartColumn5 = this.$echarts.init(
          document.querySelectorAll("#chartColumn5")[1]
        );
      }

      // this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5);
      this.chartColumn5.clear();

      let DATA = this.chart5_data;

      let dcharts = DATA.charts;
      let X = dcharts.map((node) => {
        return node.tr_date;
      });

      // 获取对象名
      let name = Object.keys(dcharts[0]);
      name.shift();

      let seriesData = [];

      name.forEach((node) => {
        if (node !== "大宗价格/收盘价") {
          let obj = {
            name: node,
            data: [],
          };
          dcharts.forEach((item) => {
            obj.data.push(item[node]);
          });
          seriesData.push(obj);
        }
      });

      let SPJDATA = dcharts.filter((node) => {
        return node["大宗价格/收盘价"] !== "";
      });

      let SPJ = SPJDATA.map((node) => {
        let arr = [node["tr_date"], node["大宗价格/收盘价"]];
        return arr;
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let mean = DATA.mean;

      console.log(mean,'mean');

      let ipo_date = DATA.ipo_date;

      let markPointData = ipo_date.map((node) => {
        let obj = {
          yAxis: 0,
          xAxis: node.IPO_date,
          symbolOffset: [0, "100%"],
          //  字体会重叠
          label: {
            normal: {
              show: true,
              // formatter: `IPO`,
              formatter: node.type_,
              color: OPTIONDATA.title.textStyle.color,
              position: ["120%", "30%"],
            },
          },
        };
        return obj;
      });

      console.log(markPointData,'markPointData');


      let theme = this.$store.state.theme;
      let lightOpacity = this.$store.state.lightOpacity;

      // let seriesArr = seriesData.map(node=>{
      //   return {

      //   }
      // })

      let option = {
        // title: {
        //   // 标题设置
        //   text: "鹏华深圳能源 REITs 大宗交易情况", // 标题文字
        //   textStyle: {
        //     // 标题文字样式设置
        //     color: "rgba(218, 218, 218, 1)",
        //     fontSize: 16,
        //   },
        //   left: "center",
        //   top: 20,
        // },

        grid: {
          top: this.ROOTFONTFIZE * (90 / 144),
          bottom: this.ROOTFONTFIZE * (30 / 144),
          right: this.ROOTFONTFIZE * (35 / 144),
          left: this.ROOTFONTFIZE * (20 / 144),
          containLabel: true,
        },
        legend: [
          {
            show: true,
            // itemWidth: 12,
            // itemHeight: 12,
            itemWidth: this.ROOTFONTFIZE * (30 / 144),
            itemHeight: this.ROOTFONTFIZE * (5 / 144),
            data: [
              {
                name: seriesData[0].name + "（左轴）",
                icon: "roundRect",
                // color:'rgba(56, 56, 118, 1)'
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            left: this.ROOTFONTFIZE * (25 / 144),
            y: this.ROOTFONTFIZE * (20 / 144),
          },
          {
            show: true,
            // itemWidth: 12,
            // itemHeight: 12,
            itemWidth: this.ROOTFONTFIZE * (30 / 144),
            itemHeight: this.ROOTFONTFIZE * (5 / 144),
            data: [
              {
                name: seriesData[1].name + "（左轴）",
                icon: "roundRect",
                // color:'rgba(56, 56, 118, 1)'
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            left: this.ROOTFONTFIZE * (170 / 144),
            y: this.ROOTFONTFIZE * (20 / 144),
          },

          {
            show: true,
            // itemWidth: 35,
            // itemHeight: 3,
            itemWidth: this.ROOTFONTFIZE * (12 / 144),
            itemHeight: this.ROOTFONTFIZE * (12 / 144),
            data: [
              {
                name: "大宗价格/收盘价（右轴）",
                // icon: "roundRect",
                icon: "circle",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            left: this.ROOTFONTFIZE * (340 / 144),
            y: this.ROOTFONTFIZE * (20 / 144),
          },
          {
            show: true,
            // itemWidth: 35,
            // itemHeight: 3,
            symbolSize: this.ROOTFONTFIZE * (18 / 144),
            data: [
              {
                name: "IPO标志",
                icon: "triangle",
                color: "rgba(212, 48, 48, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,

              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            left: this.ROOTFONTFIZE * (520 / 144),
            y: this.ROOTFONTFIZE * (20 / 144),
          },
        ],

        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            args.forEach((node, idx) => {
              let val = 0;
              if (idx < 2) {
                val = (Number(node.value) / 10000).toFixed(1);
              } else {
                //  val = (Number(node.value) * 100 ).toFixed(1)+'%';
                val = Number(node.value[1]).toFixed(2);
              }
              str += "<br>";
              str += `${node.marker}${node.seriesName}：${val}`;

              return str;
            });
            return str;
          },
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          splitNumber: 4,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            showMaxLabel: true,
            margin: this.ROOTFONTFIZE * (35 / 144),
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
        },

        yAxis: [
          {
            name: "单位：(万)",
            nameTextStyle: {
              color: OPTIONDATA.yAxis.nameTextStyle.color,
              fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
            },
            nameGap: OPTIONDATA.yAxis.nameGap,
            // scale: true,
            type: "value",
            // min:MIN_Y,
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                let value = Number(args) / 10000;
                let num = (parseInt(Math.abs(value)) + "").length >= 2 ? 1 : 2;
                args = value.toFixed(num);
                return args;
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: "dashed",
                color: OPTIONDATA.splitLine.lineStyle.color,
              },
            },
          },

          {
            // scale: true,
            name: "单位：(%)",
            nameTextStyle: {
              color: OPTIONDATA.yAxis.nameTextStyle.color,
              fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
            },
            nameGap: OPTIONDATA.yAxis.nameGap,
            type: "value",
            // min:MIN_Y,
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                args = args.toFixed(fnGetMuchDecimals(args));
                return args;
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: "dashed",
                color: OPTIONDATA.splitLine.lineStyle.color,
              },
            },
          },
        ],

        series: [
          {
            // data: [29000, 33000, 34000, 34100],
            // name: "大宗成交量（左轴）",
            name: seriesData[1].name + "（左轴）",
            data: seriesData[1].data,
            yAxisIndex: 0,
            type: "line",
            smooth: false,
            itemStyle: {
              color: "rgba(90, 202, 198, 1)",
            },
            z: 2,
            symbol: "none",
            areaStyle: {
              color: "rgba(90, 202, 198, 1)",
              opacity: theme == "dark" ? 0.5 : lightOpacity,
            },
          },

          {
            // name: "成交量（左轴）",
            // data: CJL,
            name: seriesData[0].name + "（左轴）",
            data: seriesData[0].data,
            yAxisIndex: 0,
            type: "line",
            smooth: false,
            z: 1,
            itemStyle: {
              color: "rgba(70, 71, 134, 1)",
            },
            symbol: "none",
            areaStyle: {
              color: "rgba(70, 71, 134, 1)",
              opacity: theme == "dark" ? 0.5 : lightOpacity,
            },
          },

          {
            name: "大宗价格/收盘价（右轴）",
            data: SPJ,
            yAxisIndex: 1,
            type: "scatter",
            itemStyle: {
              color: "rgba(233, 52, 52, 1)",
            },
          },

          {
            name: "q",
            yAxisIndex: 1,
            type: "line",
            smooth: false,
            itemStyle: {
              color: "rgba(86, 84, 202, 1)",
            },
            markLine: {
              symbol: ["none", "none"],

              data: [
                {
                  yAxis: mean,
                },
              ],
              lineStyle: {
                type: "solid",
                // color: "#fff",
                color: OPTIONDATA.xAxis.axisLabel.color,
                width: 2,
              },

              label: {
                show: true,
                position: "insideEndTop",
                padding: [0, 0, 0, 0],
                fontSize: 16,
                formatter: function (args) {
                  // let val = args.value * 100;
                  // val = val.toFixed(1);
                  // val = val + "%";
                  // return `{title|大宗平均折扣率: }  {value|${val}} `;

                  let val = mean;
                  val = val * 100;
                  val = val.toFixed(2);
                  val = val + "%";
                  return `{title|大宗平均折扣率: }  {value|${val}} `;
                },
                rich: {
                  title: {
                    color: OPTIONDATA.legend.textStyle.color,
                    fontSize: 14,
                  },
                  value: {
                    color: "#fff",
                    color: OPTIONDATA.xAxis.axisLabel.color,
                    fontSize: 16,
                    fontWeight: "bolder",
                  },
                },
              },
            },
            symbol: "none",
          },

          {
            name: "IPO标志",
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "rgba(212, 48, 48, 1)",
            },
            markPoint: {
              // 标记的数据，可以是最大值最小值也可以是自定义的坐标
              data: markPointData,
              symbol: "triangle", // 标记图形
              symbolSize: 18,
              symbolOffset: [0, "50%"],

              // 标注点的样式
              itemStyle: {
                color: "rgba(212, 48, 48, 1)", // 标注点颜色
                label: {
                  show: true,
                },
              },
            },
          },
        ],
      };

      this.chartColumn5.setOption(option);
      this.chartColumn5.resize();
      this.loading_chart5 = false;
      // this.funResize()
    },

    fnInit(data) {
      this.CODE = window.sessionStorage.getItem("CURRENTCODE");
      this.STOCKNAME = window.sessionStorage.getItem("CURRENTNAME");
      this.fnGetCbtns(data);
    },

    fnGetCbtns(data) {
      if (data == "资金流入情况") {
        this.bButtons1_card1 = true;
        this.bButtons1_card2 = false;
        this.bButtons1_card3 = false;
        this.dButtons1_current = "资金流入情况";
        this.fnGetFundflowPieTableReit();
      }

      if (data == "成交额及换手率对比") {
        this.bButtons1_card1 = false;
        this.bButtons1_card2 = true;
        this.bButtons1_card3 = false;
        this.fnGetDailyAmtTurnoverReit();
        this.dButtons1_current = "成交额及换手率对比";
      }

      if (data == "大宗交易情况") {
        this.bButtons1_card1 = false;
        this.bButtons1_card2 = false;
        this.bButtons1_card3 = true;
        this.dButtons1_current = "大宗交易情况";
        this.fnGetBlockTradeReit();
      }
    },

    formatDate() {
      var date = new Date();
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + " ";
    },

    fnChangeBtn1() {},
  },
  components: {
    CSelects,
    CSelect,
    CCategoryRadio,
    CButtons,
    CTimeButtons,
    CunknowButtons,
    CDialog,
    CButtonsSeparate,
    CButtonsDownload,
  },
  computed: {
    cssVars() {
      return {
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
        "--specialColor": this.SPECColor,
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
      fnDownloadBgc();

      this.$nextTick(() => {
        this.initChart1();
      });

      this.$nextTick(() => {
        this.initChart2();
      });

      this.$nextTick(() => {
        this.initChart3();
      });

      this.$nextTick(() => {
        this.initChart4();
      });

      this.$nextTick(()=>{
        this.initChart5();
      })
    },
  },
};
</script>

<style lang="less" scoped>
.container {
}

.canvasArea {
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.CARD {
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: rgba(29, 31, 37, 1);
  background-color: var(--charts_bgc);
  padding: calc(var(--ROOTFONTFIZE) * (15 / 144)) 0;
}

.CARD_tit {
  padding-left: calc(var(--ROOTFONTFIZE) * (22 / 144));
  // border-left: 5px solid rgba(21, 115, 254, 1);
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (30 / 144));
  // height: 20px;
  color: var(--chart_title);
}

.CARD_tit2 {
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: rgba(255, 255, 255, 1);
  margin-top: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.CARD_body {
  padding: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.CARD_body_tabs {
  margin-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.marketValue {
  margin-top: calc(var(--ROOTFONTFIZE) * (35 / 144));
}

.marketValue .value span:nth-child(1) {
  font-size: calc(var(--ROOTFONTFIZE) * (36 / 144));
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(255, 255, 255, 1);
}

.marketValue .value span:nth-child(2) {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(49, 208, 104, 1);
  margin-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.marketValue .time {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: calc(var(--ROOTFONTFIZE) * (16 / 144));
  color: rgba(255, 255, 255, 1);
  margin-top: calc(var(--ROOTFONTFIZE) * (18 / 144));
}

.CARD_body_echart,
.CARD_body_table {
  height: calc(var(--ROOTFONTFIZE) * (245 / 144));
  background: rgba(0, 0, 0, 0.1);
  border: 0.2px solid rgba(135, 135, 135, 1);
  border-radius: calc(var(--ROOTFONTFIZE) * (5 / 144));
  margin: calc(var(--ROOTFONTFIZE) * (40 / 144))
    calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.choose_area {
  margin-top: calc(var(--ROOTFONTFIZE) * (24 / 144));
  display: flex;
  justify-content: space-between;
}

.CTimeButtons {
  // margin-right: 60px;
}

.charts_area {
  display: flex;
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  position: relative;
}

.choose_area_left {
  flex: 1;
  margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
  height: calc(var(--ROOTFONTFIZE) * (375 / 144));
}

.info {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 0px;
  color: var(--deadline_style_color);
  padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
}

.choose_area_right {
  flex: 1;
  height: calc(var(--ROOTFONTFIZE) * (375 / 144));
}

.Cbtns_sep_chart3_style {
  margin-left: 0 !important;
}

// 流入强度  start --------------------------------------------------
.InflowIntensity {
  margin-bottom: calc(var(--ROOTFONTFIZE) * (60 / 144));
}

.InflowIntensity_title {
  text-align: center;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (20.27 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
  color: var(--stock_area_item_color1);
  position: relative;
}

.InflowIntensity_title span:nth-child(2) {
  position: absolute;
  right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.InflowIntensity_body {
  padding: 0 calc(var(--ROOTFONTFIZE) * (80 / 144));
  box-sizing: border-box;
}

.InflowIntensity_body_top {
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: calc(var(--ROOTFONTFIZE) * (0.25 / 144));
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(255, 255, 255, 0.8);
  margin-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.InflowIntensity_body_Lines {
  display: flex;
}

.InflowIntensity_body_Lines div {
  flex: 1;
  height: calc(var(--ROOTFONTFIZE) * (12 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.InflowIntensity_body_Lines div:nth-child(1) {
  background-color: rgba(0, 175, 65, 1);
  border-radius: calc(var(--ROOTFONTFIZE) * (5 / 144)) 0 0
    calc(var(--ROOTFONTFIZE) * (5 / 144));
  position: relative;
}

.InflowIntensity_body_Lines div:nth-child(2) {
  background-color: rgba(102, 207, 141, 1);
  position: relative;
}

.InflowIntensity_body_Lines div:nth-child(3) {
  position: relative;
  background-color: rgba(179, 231, 198, 1);
}

.InflowIntensity_body_Lines div:nth-child(4) {
  background-color: rgba(255, 179, 179, 1);
  position: relative;
}

.InflowIntensity_body_Lines div:nth-child(5) {
  background-color: rgba(255, 102, 102, 1);
  position: relative;
}

.InflowIntensity_body_Lines div:nth-child(6) {
  background-color: rgba(255, 0, 0, 1);
  border-radius: 0 calc(var(--ROOTFONTFIZE) * (5 / 144))
    calc(var(--ROOTFONTFIZE) * (5 / 144)) 0;
  position: relative;
}

.special:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  transform: translateY(5px);
  width: 0;
  height: 0;
  border-left: calc(var(--ROOTFONTFIZE) * (8 / 144)) solid transparent;
  border-right: calc(var(--ROOTFONTFIZE) * (8 / 144)) solid transparent;
  // border-bottom: 10px solid red;
  // border-bottom: 16px solid rgba(255, 179, 179, 1);
  border-bottom: calc(var(--ROOTFONTFIZE) * (16 / 144)) solid
    var(--specialColor);
}

.chart5ChooseArea {
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--RelatedInstitutions_item_color2);
}

.pr {
  position: relative;
}

.chart5ChooseArea span:nth-child(1) {
  display: flex;
  align-items: center;
}

// 流入强度  end --------------------------------------------------------------

// table start
.tableArea {
  height: calc(var(--ROOTFONTFIZE) * (262 / 144));
  border: none !important;
}

table {
  width: 100%;
  height: 100%;
  border: none;
}

tr:nth-child(1) {
  // height: 50px;
  // background-color: var(--table2_th_bgc);
  background-color: var(--table3_th_bgc);
}

tr:nth-child(1) th {
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 700;
  // color: var(--stock_area_item_color2);
  color: var(--table3_th_color);
  border-bottom: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid
    var(--table3_th_brc_bottom);
  // border-top: calc(var(--ROOTFONTFIZE) * (2 / 144)) solid var(--table3_th_brc_top);
}

.rowArea {
  // background-color: var(--table2_th_bgc);
  // color: var(--table2_th_rowc);

  background-color: var(--table_bgc);
  color: var(--table_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  border-bottom: 1px solid var(--table_brc);
}

.rowArea:hover {
  background-color: var(--table_bgc_hover);
  color: var(--table_color_hover);
  cursor: pointer;
}

.odd {
  // background-color:var(--table2_odd_bgc);
  // border-top: 1px solid var(--table2_odd_brc);
  // border-bottom: 1px solid var(--table2_odd_brc);
}

.rowArea td:nth-child(1) {
  text-align: center;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  // color: var(--stock_area_item_color2);
  color: var(--stock_area_item_color1);
}

.inflowStyle {
  text-align: center;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  color: rgba(227, 37, 47, 1);
}

.outflowStyle {
  text-align: center;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  color: #16a148;
}

// table end

.HEIGHT_396 {
  height: calc(var(--ROOTFONTFIZE) * (396 / 144)) !important;
}

.HEIGHT_500 {
  height: calc(var(--ROOTFONTFIZE) * (500 / 144)) !important;
}

.HEIGHT_400 {
  height: calc(var(--ROOTFONTFIZE) * (400 / 144)) !important;
}

.HEIGHT_262 {
  height: calc(var(--ROOTFONTFIZE) * (262 / 144)) !important;
}
</style>
